import React from 'react'
import PropTypes from 'prop-types'
import { withFormik, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { Button, InputLocalized } from '@gymondo/frontend-core/components'
import { REGEX } from '@gymondo/frontend-core/utils'
import { requestPassword } from '../../../api/service/login/login.service'

import style from './forgot-password-form.module.scss'

const ForgotPasswordForm = (props) => {
    const {
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        status: { success },
    } = props

    const message = success
        ? 'forgotPasswordForm.success'
        : 'forgotPasswordForm.message'

    return (
        <form onSubmit={handleSubmit}>
            <div className={style.message}>
                <FormattedMessage id={message} />
            </div>
            {!success && (
                <>
                    <Field
                        name="mail"
                        variant="secondary"
                        placeholder="forgotPasswordForm.placeholder"
                        component={InputLocalized}
                    />
                    <Button
                        fluid
                        htmlType="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting || !isValid || !dirty}
                    >
                        <FormattedMessage id="forgotPasswordForm.request" />
                    </Button>
                </>
            )}
        </form>
    )
}

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    status: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
}

ForgotPasswordForm.defaultProps = {
    status: {},
}

const hasWhiteSpace = (s) => {
    return /\s/g.test(s)
}

const EnhancedForm = withFormik({
    validateOnBlur: true,
    mapPropsToValues: () => ({ mail: '' }),
    validate: (values) => {
        const errors = {}

        if (!REGEX.email.test(values.mail) || hasWhiteSpace(values.mail)) {
            errors.mail = 'validation.emailFormat'
        }
        // If users clear inputs do not show error msg
        if (values.mail === '') {
            return {}
        }

        return errors
    },

    handleSubmit: async (values, { setSubmitting, setErrors, setStatus }) => {
        try {
            await requestPassword(values.mail)
            setSubmitting(false)
            setStatus({ success: true })
        } catch (e) {
            setErrors({ mail: _.get(e, 'response.data.message') })
            setSubmitting(false)
        }
    },
    displayName: 'forgotPasswordForm',
})(ForgotPasswordForm)

export default EnhancedForm
