import jsCookie from 'js-cookie'
import { LocalStorage, SessionStorage } from '@gymondo/frontend-core/utils'
import {
    COOKIE_NAMES,
    SESSION_STORAGE_KEYS,
    LOCAL_STORAGE_KEYS,
} from '@gymondo/frontend-core/enums'
import moment from 'moment'
import * as ACTIONS from './user.constants'
import status from '../../api/generic/redux/status.enum'
import { SIGNUP_ACTIONS } from '../../config/definitions'

const loggedInCookie = jsCookie.get(COOKIE_NAMES.OAUTH2_REFRESH_TOKEN)
const existingUserCookie =
    typeof window !== 'undefined' && window.document
        ? jsCookie.get(COOKIE_NAMES.EXISTING_USER)
        : true
const channelName = SessionStorage.get(SESSION_STORAGE_KEYS.CHANNEL)
const channelId = SessionStorage.get(SESSION_STORAGE_KEYS.CHANNEL_ID)
/** @type {Product} */
const selectedProduct = LocalStorage.get(LOCAL_STORAGE_KEYS.SELECTED_PRODUCT)
const signupAction = LocalStorage.get(LOCAL_STORAGE_KEYS.SIGNUP_ACTION)
const isTrial = LocalStorage.get(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL)
/** @type {Voucher} */
const signupVoucher = SessionStorage.get(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER)

/**
 * @typedef {Object} EndTrialTimeResult
 * @property {number|null} endTrialTime
 * @property {string|null} [trialInterval]
 */

/**
 * @param {Product} product
 * @returns {EndTrialTimeResult} */
const getEndTrialTime = (product) => {
    // optional chaining is not supported by compiler in gatsby build level
    if (
        !product ||
        !product.conditions ||
        !product.conditions.trialInterval ||
        !product.conditions.trialDuration
    ) {
        return {
            trialInterval: null,
            endTrialTime: null,
        }
    }

    return {
        trialInterval: `${product.conditions.trialDuration} ${product.conditions.trialInterval}`,
        endTrialTime: moment()
            .add(
                product.conditions.trialDuration,
                /** @type {moment.unitOfTime.DurationConstructor} */
                product.conditions.trialInterval.toLowerCase()
            )
            .valueOf(),
    }
}

const getSignupAction = (currentSignupAction, roles) => {
    if (
        roles &&
        roles.includes('ROLE_USER_EXPIRED') &&
        !roles.includes('ROLE_USER_INACTIVE')
    ) {
        return SIGNUP_ACTIONS.REPURCHASE
    }
    if (
        roles &&
        roles.includes('ROLE_USER_DISABLED') &&
        roles.includes('ROLE_USER_REACTIVATION_ALLOWED')
    ) {
        return SIGNUP_ACTIONS.REACTIVATION
    }

    return currentSignupAction
}

/**
 * @typedef {Object} UserState
 * @property {boolean} isLoggedIn
 * @property {boolean} isExistingUser
 * @property {boolean} isTrial
 * @property {Product | null} selectedProduct
 * @property {Object | null} channel
 * @property {string | null} signupAction
 * @property {Voucher | null} signupVoucher
 * @property {string | null} userName
 * @property {any} error
 * @property {string[]} roles
 * @property {boolean} shouldTryNextStep
 * @property {string} status
 * @property {string | null} trialInterval
 * @property {number | null} endTrialTime
 */

/**
 * @type {UserState}
 */
const initialState = {
    isLoggedIn: !!loggedInCookie,
    isExistingUser: !!existingUserCookie,
    isTrial: !!isTrial,
    selectedProduct,
    channel:
        !channelName && !channelId
            ? null
            : { id: channelId, name: channelName },
    signupAction,
    signupVoucher,
    userName: null,
    error: null,
    roles: null,
    shouldTryNextStep: false,
    status: status.PENDING,
    ...getEndTrialTime(selectedProduct),
}

/**
 * @param {UserState} state
 * @param {Object} action
 * @returns {UserState}
 */
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ACTIONS.LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                status: status.OK,
            }
        case ACTIONS.LOGOUT: {
            return {
                ...state,
                userName: null,
                isLoggedIn: false,
                isTrial: false,
                signupAction: null,
            }
        }
        case ACTIONS.PENDING: {
            return {
                ...state,
                status: status.PENDING,
            }
        }
        case ACTIONS.FETCH_SUCCESS:
            return {
                ...state,
                userName: payload.username,
                id: payload.id,
                status: status.OK,
                roles: payload.roles,
                signupAction: getSignupAction(
                    state.signupAction,
                    payload.roles
                ),
            }
        case ACTIONS.FETCH_ERROR:
            return {
                ...state,
                userName: null,
                error: payload,
                status: status.ERROR,
            }
        case ACTIONS.SET_CHANNEL:
            return {
                ...state,
                channel: payload,
            }
        case ACTIONS.SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: payload,
                ...getEndTrialTime(payload),
            }
        case ACTIONS.SET_SIGNUP_ACTION:
            return {
                ...state,
                signupAction: payload,
            }
        case ACTIONS.SET_SIGNUP_VOUCHER:
            return {
                ...state,
                signupVoucher: payload,
                ...getEndTrialTime(state.selectedProduct),
            }
        case ACTIONS.TRY_NEXT_STEP:
            return {
                ...state,
                shouldTryNextStep: payload,
            }
        case ACTIONS.SET_TRIAL: {
            return {
                ...state,
                isTrial: payload,
            }
        }
        default:
            return state
    }
}

export default reducer
