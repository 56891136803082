import { COOKIE_CONSENT_KEYS } from '@gymondo/frontend-core/enums'
import { getConsentValue } from '@gymondo/frontend-core/components/cookie-consent/util'
import jsCookie from 'js-cookie'

/**
 * In order not to have cookie consent popup on both gymondo and shop.gymondo we
 * have to format the cookie consent in a way that shop can understand,
 * atm shop is build in Shopify and needs the following:
 * - Information to be persistent in cookie named "_bc_c_set"
 * - Format {true: 0 || 1: false: 0 || 1}
 * - cookie value encoded
 * - version, which might be subject to change
 */
export const setGymondoShopCookieConsentString = () => {
    const currentTimestamp = new Date()
    const consentVersion = '20201216225107'
    const gymondoShopCookieConsentName = '_bc_c_set'
    const cookieLifeTime = 756 // days

    const consentString = {
        date: currentTimestamp.getTime(),
        version: consentVersion,
        type_0: getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_MANDATORY_OPTIN)
            ? { true: 1, false: 0 }
            : { true: 0, false: 1 },
        type_1: getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_ANALYTICS_OPTIN)
            ? { true: 1, false: 0 }
            : { true: 0, false: 1 },
        type_2: getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN)
            ? { true: 1, false: 0 }
            : { true: 0, false: 1 },
    }

    jsCookie.set(
        gymondoShopCookieConsentName,
        window.btoa(JSON.stringify(consentString)),
        {
            expires: cookieLifeTime,
        }
    )
}
