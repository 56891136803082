import CRUD from '../../generic/crud/crud'
import URL from '../../../config/url'

/**
 * @typedef {import('@gymondo/frontend-core/types').CampaignProductDTO} CampaignProductDTO
 * @typedef {import('@gymondo/frontend-core/types').Product} Product
 * @typedef {import('@gymondo/frontend-core/types').Voucher} Voucher
 * @typedef {import('@gymondo/frontend-core/types').Campaign} Campaign
 */

/**
 * Fetches products with voucher and channel ID
 * @param {Number} [channelId] Id of current channel
 * @param {String} [voucherCode] active voucher
 * @param {boolean} [allowVoucher] flag to allow voucher
 * @param {boolean} [withCampaign] flag to fetch campaign
 * @returns {Promise<CampaignProductDTO>} Campaign Products containing Products, voucher Response and Campaign Response
 */
export const getCampaignProducts = async ({
    channelId,
    voucherCode,
    allowVoucher = true,
    withCampaign = false,
}) => {
    try {
        const { data } = await CRUD.get(URL.publicCampaignProducts, {
            params: {
                channelId,
                voucherCode,
                allowVoucher,
                withCampaign,
            },
        })

        return data
    } catch (error) {
        return { products: [] }
    }
}
