export const debounce = (func, timeout) => {
    let timer

    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, timeout)
    }
}

const RETRY_TIME_MS = 1000
export const waitFor = async (callback, retryTimeMs = RETRY_TIME_MS) => {
    while (!(await callback())) {
        await new Promise((resolve) => setTimeout(resolve, retryTimeMs))
    }
}
