import React from 'react'
import { FormattedMessage } from 'react-intl'
import { LinkLocalized } from '../..'
import { ROUTES_ID } from '../../../config/route'

const CookieBannerContent = () => (
    <>
        <FormattedMessage id="header.cookieContent" />{' '}
        <LinkLocalized
            to={ROUTES_ID.PRIVACY}
            anchor="#5-cookies"
            title="header.cookieLink"
        >
            <FormattedMessage id="header.cookieLink" />
        </LinkLocalized>
    </>
)

export default CookieBannerContent
