export const popupCenter = ({ url, title, w, h }) => {
    const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY

    // eslint-disable-next-line no-nested-ternary
    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : // eslint-disable-next-line no-restricted-globals,no-undef
          screen.width
    // eslint-disable-next-line no-nested-ternary
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : // eslint-disable-next-line no-restricted-globals,no-undef
          screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop

    return window.open(
        url,
        title,
        `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
}
