import {
    SET_LOGIN_MODAL_CLOSED,
    SET_LOGIN_MODAL_OPEN,
    SET_LOGIN_MODAL_TYPE,
} from './page-header.constants'

export const openLoginModal = (forceOpen) => ({
    type: SET_LOGIN_MODAL_OPEN,
    payload: forceOpen,
})

export const closeLoginModal = () => ({
    type: SET_LOGIN_MODAL_CLOSED,
})

export const setLoginModalLogin = () => ({
    type: SET_LOGIN_MODAL_TYPE,
    payload: 'login',
})

export const setLoginModalReset = () => ({
    type: SET_LOGIN_MODAL_TYPE,
    payload: 'reset',
})

export const setLoginModalChangePassword = () => ({
    type: SET_LOGIN_MODAL_TYPE,
    payload: 'change',
})
