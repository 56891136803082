/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'

import style from './content-grid.module.scss'

export const renderLink = (intl, link, children) => {
    return (
        <a
            href={intl.formatMessage({
                id: link.href,
            })}
            target="_blank"
            rel="noopener noreferrer"
            {...link.tracking}
        >
            {children}
        </a>
    )
}

export const renderTextPart = ({
    intl,
    title,
    subtitle,
    text,
    children,
    link,
}) => (
    <>
        {title && (
            <h4 className={style.title}>
                <FormattedMessage id={title} />
            </h4>
        )}

        {subtitle && (
            <div className={style.subtitle}>
                <FormattedMessage id={subtitle} />
            </div>
        )}

        {text && (
            <div className={style.text}>
                <FormattedMessage id={text} />
            </div>
        )}

        {link ? renderLink(intl, link, children) : children}
    </>
)

export const renderVisualPart = (intl, children, link) => (
    <>{link ? renderLink(intl, link, children) : children}</>
)

const ContentGrid = ({ config, descriptionChildren, visualChildren }) => {
    const intl = useIntl()
    const { className, isReversed, link, title, subtitle, text } = config

    return (
        <div
            className={cn(
                'row',
                className,
                style.sectionWrapper,
                isReversed && style.reverse
            )}
        >
            <div className="col-xs-12 col-sm-6">
                {renderVisualPart(intl, visualChildren, link && link[1])}
            </div>
            <div className="col-xs-12 col-sm-6">
                {renderTextPart({
                    intl,
                    title,
                    subtitle,
                    text,
                    children: descriptionChildren,
                    link: link && link[0],
                })}
            </div>
        </div>
    )
}

ContentGrid.propTypes = {
    config: PropTypes.object.isRequired,
    descriptionChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    visualChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default ContentGrid
