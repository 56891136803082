import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button } from '@gymondo/frontend-core/components'
import { Tracker } from '@gymondo/frontend-core/utils'
import { PATHS } from '../../../config/route'
import { logout } from '../../../service/user'

import style from './login-button.module.scss'

export const LoginButton = ({
    onClick,
    bordered,
    isLoggedIn,
    intl,
    userName,
    showLogout,
    logoutUser,
    showUserName,
}) => {
    let formattedMessageId
    let clickCallback
    if (isLoggedIn && showLogout) {
        formattedMessageId = 'header.logout'
        clickCallback = () => {
            logoutUser()
        }
    } else if (isLoggedIn) {
        formattedMessageId = 'header.myTraining'
        clickCallback = () => {}
    } else {
        formattedMessageId = 'header.login'
        clickCallback = onClick
    }

    const component = (
        <div
            className={cn(style.wrapper, bordered && style.bordered)}
            {...(!isLoggedIn
                ? {
                      'data-evt': Tracker.EVENT_TYPES.CLICK,
                      'data-category': 'global',
                      'data-action': 'click_login_cta',
                      'data-label': 'login_header',
                  }
                : {})}
        >
            {showUserName && userName && (
                <div className={style.userName}>
                    <FormattedMessage
                        id="header.loggedinAs.dynamic"
                        values={{ value: userName }}
                    />
                </div>
            )}
            <Button
                variant={bordered ? 'primary' : 'secondary'}
                size="small"
                onClick={clickCallback}
                bordered={bordered}
            >
                <FormattedMessage id={formattedMessageId} />
            </Button>
        </div>
    )

    if (isLoggedIn && !showLogout) {
        return (
            <a
                href={PATHS.timeline}
                title={intl.formatMessage({ id: 'header.myTraining' })}
            >
                {component}
            </a>
        )
    }

    return component
}

LoginButton.propTypes = {
    onClick: PropTypes.func,
    bordered: PropTypes.bool,
    showUserName: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired,
}

LoginButton.defaultProps = {
    onClick: () => {},
    bordered: true,
    showUserName: false,
}

export const mapStateToProps = ({ user }) => ({
    ...user,
})

export const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(logout()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LoginButton))
