import { useEffect, useRef } from 'react'
import { setCookiesToken } from '@gymondo/frontend-core/api'
import { useDispatch } from 'react-redux'
import jsCookie from 'js-cookie'
import COOKIE_NAMES from '@gymondo/frontend-core/enums/cookie-names.enum'
import { login } from '../../service/user'
import { popupCenter } from '../../page/generic/checkout-signup/component/form/popup.util'
import useInterval from '../use-interval/use-interval.hook'

const SOCIAL_LOGIN_URI = process.env.GATSBY_SOCIAL_LOGIN_URI

const useSocialLogin = ({ onError = () => {}, onSuccess = () => {} }) => {
    const dispatch = useDispatch()
    const popup = useRef(null)
    const realm = useRef(null)

    const startFlow = (app) => {
        realm.current = app
        jsCookie.remove('authorization')
        const openPopup = (provider) => {
            popup.current = popupCenter({
                url: `${SOCIAL_LOGIN_URI}?identity_provider=${provider}`,
                title: 'LoginPopup',
                w: 600,
                h: 600,
            })
        }
        openPopup(app)
    }

    useInterval(() => {
        if (popup.current != null && popup.current?.closed) {
            jsCookie.remove('authorization')
            popup.current = null
        }
    }, 1000)

    useEffect(() => {
        const handleEvent = (e) => {
            if (e.origin !== window.location.origin) {
                return
            }
            if (e.data.refresh_token) {
                setCookiesToken(e.data)
                jsCookie.set(COOKIE_NAMES.OAUTH2_REALM, realm.current, {
                    expires: 36500, // 100 years
                    path: '/',
                })
                dispatch(login())
                onSuccess()
                // eslint-disable-next-line no-unused-expressions
                popup.current?.close()
            }
            if (e.data.error && e.data.error_description) {
                onError(e.data.error, realm.current)
                // eslint-disable-next-line no-unused-expressions
                popup.current?.close()
            }
        }
        window.addEventListener('message', handleEvent, false)

        return () => window.removeEventListener('message', handleEvent, false)
    }, [dispatch, onError, onSuccess])

    return {
        startFlow,
    }
}

export default useSocialLogin
