import moment from 'moment'

export const getLocalizedTrialInterval = (intl, trialInterval, withHyphen) => {
    if (trialInterval) {
        const [value, interval] = trialInterval.split(' ')
        const unit = moment.normalizeUnits(interval)
        const hyphen = withHyphen ? '.hyphen' : ''

        return intl.formatMessage(
            { id: `duration.${unit}${hyphen}.dynamic` },
            { value }
        )
    }

    return null
}

export const getTrialTitle = (intl) => {
    return intl.formatMessage({ id: 'cta.trialInterval.static' })
}
