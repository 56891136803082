import status from '../../../../api/generic/redux/status.enum'

import {
    LANDING_PROGRAMS_SUCCESS,
    LANDING_PROGRAMS_PENDING,
    LANDING_PROGRAMS_ERROR,
    LANDING_PROGRAMS_SORT,
    LANDING_PROGRAMS_FILTERS,
    LANDING_PROGRAMS_CHANGE_FILTER,
    LANDING_PROGRAMS_RESET_FILTERS,
    LANDING_PROGRAMS_TOGGLE_DRAWER,
} from './program.constants'

import { SORT } from '../conf/sort.conf'

const GOAL_FIELDS = [
    { name: 'lose_weight', selected: false, category: 'goal', value: 15 },
    { name: 'toning', selected: false, category: 'goal', value: 14 },
    { name: 'build_muscle', selected: false, category: 'goal', value: 12 },
    { name: 'relaxation', selected: false, category: 'goal', value: 13 },
]

const INTENSITY_FIELDS = [
    { name: 'beginner', selected: false, category: 'intensityLevel', value: 1 },
    {
        name: 'intermediate',
        selected: false,
        category: 'intensityLevel',
        value: 2,
    },
    { name: 'advanced', selected: false, category: 'intensityLevel', value: 3 },
]

export const FILTER_FIELDS = [...GOAL_FIELDS, ...INTENSITY_FIELDS]

const initialState = {
    sort: SORT.PUBLISHED_ON,
    filters: FILTER_FIELDS,
    drawerOpen: false,
}

const filterContent = (state = initialState, action) => {
    switch (action.type) {
        case LANDING_PROGRAMS_SUCCESS: {
            return {
                ...state,
                status: status.OK,
                data: action.payload,
            }
        }
        case LANDING_PROGRAMS_PENDING: {
            return {
                ...state,
                status: status.PENDING,
            }
        }
        case LANDING_PROGRAMS_ERROR: {
            return {
                ...state,
                status: status.ERROR,
            }
        }
        case LANDING_PROGRAMS_SORT: {
            return {
                ...state,
                sort: action.payload,
            }
        }
        case LANDING_PROGRAMS_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        case LANDING_PROGRAMS_CHANGE_FILTER: {
            return {
                ...state,
                filters: state.filters.map((filter) => ({
                    ...filter,
                    selected:
                        filter.name === action.payload
                            ? !filter.selected
                            : filter.selected,
                })),
            }
        }
        case LANDING_PROGRAMS_RESET_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
            }
        }
        case LANDING_PROGRAMS_TOGGLE_DRAWER: {
            return {
                ...state,
                drawerOpen: action.payload,
            }
        }
        default:
            return state
    }
}

export default filterContent
