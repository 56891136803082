import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import favicon from '@gymondo/frontend-core/svg/favicon.ico'
import _ from 'lodash'
import { LANGUAGES } from '@gymondo/frontend-core/enums'
import socialNetworkEN from '../../static/image/generic/gymondo_social_en.jpg'
import socialNetworkDE from '../../static/image/generic/gymondo_social_de.jpg'
import { isProductionEnv } from '../../util/env.util'
import siteMetadata from '../../config/site-metadata.json'

/**
 * This component, gets the SEO information for each page and print it in the <head />
 * @param {object} seoInfo
 * @param {object} seoDefault
 * @param {string} locale
 */
const SEO = ({
    seoSpecific,
    messages,
    pageName,
    lang,
    pageUrls,
    redirectURL,
}) => {
    const getValue = (param) =>
        seoSpecific[param] ||
        messages[`${pageName}.seo.${param}`] ||
        messages[`seo.${param}`]

    const titleWithTemplate = (title, template) =>
        getValue(title)
            ? `${getValue(title)} | ${getValue(template)}`
            : getValue(template)

    const getMetas = () =>
        _.union(
            [
                {
                    name: 'og:title',
                    content: titleWithTemplate('title', 'og:title-template'),
                },
                {
                    name: 'twitter:title',
                    content: titleWithTemplate(
                        'title',
                        'twitter:title-template'
                    ),
                },
                {
                    name: 'robots',
                    content: !isProductionEnv
                        ? 'noindex, nofollow, noodp, noarchive, noydir'
                        : getValue('robots'),
                },
                ...((redirectURL && [
                    {
                        'http-equiv': 'refresh',
                        content: `0; URL=${redirectURL}`,
                    },
                ]) ||
                    []),
            ],
            [
                'description',
                'keywords',
                'og:description',
                'og:locale',
                'og:image',
                'twitter:description',
                'twitter:card',
                'twitter:site',
                'twitter:image',
            ].map((meta) => ({
                name: meta,
                content: getValue(meta),
            })),
            ['og:image', 'twitter:image'].map((meta) => ({
                name: meta,
                content:
                    lang === LANGUAGES.ENGLISH
                        ? socialNetworkEN
                        : socialNetworkDE,
            }))
        ).filter((meta) => meta.content)

    const canonicalLink = getValue('canonical')
    const links = [
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
        ...pageUrls.map((pUrl) => ({
            rel: 'alternate',
            hrefLang: pUrl.lang,
            href: `${siteMetadata.siteUrl}${pUrl.url}`,
        })),
    ]
    if (canonicalLink) {
        links.push({
            rel: 'canonical',
            href: canonicalLink,
        })
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={titleWithTemplate('title', 'title-template')}
            meta={getMetas()}
            link={links}
        />
    )
}

/**
 * @param {object} pageName - Page name, used to get page-specific SEO copies
 * @param {object} messages - Language-specific copies (containing default & page-specific SEO info)
 * @param {object} seoSpecific - SEO params especific to the page (from gatsby-node)
 * @param {string} redirectURL - forces an immediate redirect to the URL with 301 http header
 */
SEO.propTypes = {
    pageName: PropTypes.string,
    messages: PropTypes.object,
    seoSpecific: PropTypes.object,
    redirectURL: PropTypes.string,
    lang: PropTypes.string.isRequired,
    pageUrls: PropTypes.array,
}

SEO.defaultProps = {
    pageName: '',
    messages: {},
    seoSpecific: {},
    redirectURL: null,
    pageUrls: [],
}

export default SEO
