export const NAME = 'user'

export const LOGIN = `${NAME}/LOGIN`

export const LOGOUT = `${NAME}/LOGOUT`

export const FETCH_SUCCESS = `${NAME}/FETCH_SUCCESS`

export const FETCH_ERROR = `${NAME}/FETCH_ERROR`

export const SET_CHANNEL = `${NAME}/SET_CHANNEL`

export const SET_SELECTED_PRODUCT = `${NAME}/SET_SELECTED_PRODUCT`

export const SET_SIGNUP_VOUCHER = `${NAME}/SET_SIGNUP_VOUCHER`

export const SET_SIGNUP_ACTION = `${NAME}/SET_SIGNUP_ACTION`

export const CLEAN_SIGNUP = `${NAME}/CLEAN_SIGNUP`

export const TRY_NEXT_STEP = `${NAME}/TRY_NEXT_STEP`

export const SET_TRIAL = `${NAME}/SET_TRIAL`

export const PENDING = `${NAME}/PENDING`
