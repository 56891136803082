/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/de'
import objectFitImages from 'object-fit-images'
import qs from 'query-string'
import jsCookie from 'js-cookie'
import { Tracker, SessionStorage } from '@gymondo/frontend-core/utils'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'
import { QUERY_PARAMS } from './src/config/definitions'
import { getUserLocale } from './src/api/generic/crud/crud'

import './src/style/index.scss' // global app style
import '@adyen/adyen-web/dist/adyen.css'
import 'moment/locale/de'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import 'intl-pluralrules'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import 'matchmedia-polyfill'
import 'matchmedia-polyfill/matchMedia.addListener'

import wrapWithReduxProvider from './src/config/redux/wrap-with-provider'
import wrapWithI18nProvider from './src/config/intl/wrap-with-intl'
import { trackVirtualPageView } from './src/util/location.util'

export const wrapPageElement = wrapWithI18nProvider

export const wrapRootElement = wrapWithReduxProvider

// add tracker to the pages
export const onClientEntry = async () => {
    //
    const isEdge15 = /Edge\/15/.test(navigator.userAgent)
    // Force polyfill of IntersectionObserver on Edge 15
    // because calling unobserve() on an unobserved element results in TypeMismatchError
    if (isEdge15) {
        // eslint-disable-next-line
        IntersectionObserver = undefined
    }
    if (typeof IntersectionObserver === 'undefined') {
        await import('intersection-observer')
    }

    Tracker.registerTracker()
}

/**
 * When the app loads, send website tracking
 */
const pushInitialTracking = () => {
    const location = typeof window !== 'undefined' && window?.location

    const queryParams = qs.parse(location && location.search)
    const channel =
        queryParams[QUERY_PARAMS.CHANNEL] ||
        jsCookie.get(COOKIE_NAMES.INFLUENCER_SOURCE)

    Tracker.push(
        {
            event: Tracker.EVENTS.SET_CUSTOM_DIMENSION,
            name: 'website',
            value: channel || 'GYM',
        },
        {
            event: Tracker.EVENTS.SET_CUSTOM_DIMENSION,
            name: 'environment',
            value: 'pre_login',
        },
        {
            newRelicID: process.env.GATSBY_NEW_RELIC_ID,
        },
        {
            userLocale: getUserLocale(),
        }
    )

    Tracker.push({
        event: 'optimize.activate',
        optimizeIsExperiment:
            SessionStorage.get('optimize_experiment') || false,
    })

    const redirectSource = queryParams.redirect_source
    if (redirectSource) {
        Tracker.push({
            event: Tracker.EVENTS.TRACKING,
            eventCategory: 'redirect',
            eventAction: 'legacy',
            eventLabel: redirectSource,
        })
    }
}

// Solves cover image problem for gatsby-image
// See: https://github.com/gatsbyjs/gatsby/issues/2470
export const onInitialClientRender = () => {
    objectFitImages()

    /**
     * Push to GTM
     */
    pushInitialTracking()
}

/**
 * Send to dataLayer the new URL. Remove locale information.
 * E.g.: /en/contact => /contact
 * @param {Object} Information about the current URL
 */
export const onRouteUpdate = ({ location }) => {
    trackVirtualPageView(location, true)
}
