import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './background-image.module.scss'

const BackgroundImage = ({
    classParentName,
    imgClassName,
    childrenClassName,
    gradient,
    overlay,
    children,
    bottomGradient,
    ...rest
}) => (
    <div className={classParentName}>
        {(rest.fluid || rest.fixed) && (
            <Img {...rest} className={cn('image__background', imgClassName)} />
        )}

        {gradient && (
            <div
                className={cn('image__gradient', {
                    [styles.bottomGradient]: bottomGradient,
                })}
            />
        )}
        {overlay && <div className="image__background shadow-inset" />}
        {children && (
            <div className={cn('position--relative', childrenClassName)}>
                {children}
            </div>
        )}
    </div>
)

BackgroundImage.defaultProps = {
    classParentName: null,
    imgClassName: null,
    childrenClassName: null,
    gradient: false,
    bottomGradient: false,
    overlay: false,
    children: null,
}

BackgroundImage.propTypes = {
    classParentName: PropTypes.string,
    imgClassName: PropTypes.string,
    childrenClassName: PropTypes.string,
    gradient: PropTypes.bool,
    bottomGradient: PropTypes.bool,
    overlay: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default BackgroundImage
