import React, { useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import { Tracker } from '@gymondo/frontend-core/utils'
import jsCookie from 'js-cookie'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'

import useInterval from '../../../hook/use-interval/use-interval.hook'

import style from './campaign-banner.module.scss'

import { getTrialTitle } from '../../../util/trial-interval.util'

import { LinkLocalized } from '../..'
import { getCampaignProducts } from '../../../api/service/campaign-product/campaign-product.service'

const renderTimeUnit = (value, formattedMessageId) => (
    <div className={style.countdown}>
        <div className={style.countdownValue}>{value}</div>
        <div className={style.countdownUnit}>
            <FormattedMessage
                values={{ count: value }}
                id={formattedMessageId}
            />
        </div>
    </div>
)

/**
 * @param {string} className
 * @param {string} title
 * @param {string} [text]
 * @returns {JSX.Element}
 */
const renderContent = (className, title, text = '') => (
    <div className={className}>
        {title} {text}
    </div>
)

const getRemainingTime = (campaign) => {
    const campaignEnd = moment.utc(campaign.end).subtract(1, 'second')

    return moment.utc(campaignEnd.diff(moment()))
}

export const CampaignBanner = ({
    isExistingUser,
    isLoggedIn,
    hasAnalyticsConsent,
}) => {
    const intl = useIntl()
    const [campaign, setCampaign] = useState(
        /** @type {Campaign | null} */ null
    )
    const [date, setDate] = useState(null)

    const ranOut = date && date.valueOf() <= 0
    const region = jsCookie.get(COOKIE_NAMES.USER_REGION)
    const DISPLAY_COUNTDOWN_REGIONS = ['DE', 'AT', 'CH']
    const hasCountdown = DISPLAY_COUNTDOWN_REGIONS.includes(region)

    useInterval(
        () => {
            if (!isExistingUser && !isLoggedIn && campaign) {
                const remainingTime = getRemainingTime(campaign)
                setDate(remainingTime)
            }
        },
        ranOut ? null : 1000
    )

    useLayoutEffect(() => {
        ;(async () => {
            if (!isExistingUser && !isLoggedIn) {
                /**
                 * TODO: although we get products that we can pass to packages screen here
                 * this is currently not possible since we don't have channel or voucher info
                 * moreover most of package is handled in Public Pages now, revisit in the future if this changes
                 */
                const campaignProduct = await getCampaignProducts({
                    withCampaign: true,
                })
                const currentCampaign = campaignProduct.campaign

                if (currentCampaign) {
                    setCampaign(currentCampaign)
                    setDate(getRemainingTime(currentCampaign))
                }
            }
        })()
    }, [isExistingUser, isLoggedIn, intl.locale, region, hasAnalyticsConsent])

    if (ranOut) {
        return null
    }

    if (date && campaign && !isExistingUser && !isLoggedIn) {
        const banner = (
            <div className={style.wrapp}>
                <div className={hasCountdown && style.content}>
                    {renderContent(
                        style.desktopContent,
                        campaign.desktopTitle,
                        campaign.desktopText
                    )}
                    {renderContent(style.mobileContent, campaign.mobileText)}
                </div>
                {hasCountdown && (
                    <>
                        {renderTimeUnit(
                            date.hour(),
                            'campaignBanner.hourWithoutValue'
                        )}
                        {renderTimeUnit(
                            date.minute(),
                            'campaignBanner.minuteWithoutValue'
                        )}
                        {renderTimeUnit(
                            date.second(),
                            'campaignBanner.secondWithoutValue'
                        )}
                    </>
                )}
            </div>
        )

        return (
            <LinkLocalized
                to="checkout"
                title={getTrialTitle(intl)}
                data-evt={Tracker.EVENT_TYPES.CLICK}
                data-category="global"
                data-action="click_register_cta"
                data-label="register_campaign"
            >
                {banner}
            </LinkLocalized>
        )
    }

    return null
}

CampaignBanner.propTypes = {
    isExistingUser: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    hasAnalyticsConsent: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ user, cookieConsent }) => ({
    ...user,
    ...cookieConsent,
})

export default connect(mapStateToProps)(CampaignBanner)
