const DEVELOPMENT_ENVIRONMENT = 'development'
const TEST_ENVIRONMENT = 'test'
const STAGE_ENVIRONMENT = 'stage'
const PRODUCTION_ENVIRONMENT = 'production'

// Use es5 here because is used in gatsby-config as well.
const getCurrentEnv = () =>
    (process &&
        (process.env.GATSBY_ENV ||
            process.env.ACTIVE_ENV ||
            process.env.NODE_ENV)) ||
    DEVELOPMENT_ENVIRONMENT

const isDevelopmentEnv = getCurrentEnv() === DEVELOPMENT_ENVIRONMENT
const isTestEnv = getCurrentEnv() === TEST_ENVIRONMENT
const isStageEnv = getCurrentEnv() === STAGE_ENVIRONMENT
const isProductionEnv = getCurrentEnv() === PRODUCTION_ENVIRONMENT

module.exports = {
    getCurrentEnv,
    isDevelopmentEnv,
    isTestEnv,
    isStageEnv,
    isProductionEnv,
}
