import { LocalStorage } from '@gymondo/frontend-core/utils'
import { LOCAL_STORAGE_KEYS } from '@gymondo/frontend-core/enums'
import * as actions from './affiliate.constants'

export const setAffiliate = (
    affiliatePid,
    affiliateId,
    hasMarketingConsent
) => dispatch => {
    if (hasMarketingConsent) {
        LocalStorage.set(affiliatePid, affiliateId)
    } else {
        dispatch({
            type: actions.SET_AFFILIATE,
            payload: {
                [affiliatePid]: affiliateId,
            },
        })
    }
}

export const getAffiliate = affiliatePid => (_, getStore) => {
    return (
        LocalStorage.get(affiliatePid) ||
        getStore().affiliate.affiliateData[affiliatePid]
    )
}

export const removeAffiliate = () => dispatch => {
    const affiliatePids = [
        LOCAL_STORAGE_KEYS.SIGNUP_ZANOX,
        LOCAL_STORAGE_KEYS.SIGNUP_SOVENDUS,
    ]

    affiliatePids.forEach(affiliatePid => {
        LocalStorage.remove(affiliatePid)
        dispatch({
            type: actions.REMOVE_AFFILIATE,
            payload: affiliatePid,
        })
    })
}

export const loadAffiliateIntoLocalStorage = () => (_, getStore) => {
    const affiliateIds = getStore().affiliate.affiliateData
    const { hasMarketingConsent } = getStore().cookieConsent
    if (hasMarketingConsent && affiliateIds) {
        Object.keys(affiliateIds).forEach(affilitatePId =>
            LocalStorage.set(affilitatePId, affiliateIds[affilitatePId])
        )
    }
}
