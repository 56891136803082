import React from 'react'
import PropTypes from 'prop-types'
import { withFormik, Field } from 'formik'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
    Button,
    InputNote,
    InputLocalized as Input,
} from '@gymondo/frontend-core/components'
import { password as passwordValidator } from '@gymondo/frontend-core/validators'
import { changePassword } from '../../../api/service/login/login.service'
import { login } from '../../../service/user'

const ChangePasswordForm = props => {
    const {
        handleSubmit,
        isSubmitting,
        errors,
        status: { success },
    } = props

    if (success) {
        return <FormattedMessage id="changePasswordForm.success" />
    }

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="password"
                variant="secondary"
                placeholder="changePasswordForm.password.placeholder"
                type="password"
                component={Input}
                validate={passwordValidator}
            />
            <Field
                name="confirm_password"
                variant="secondary"
                placeholder="changePasswordForm.confirm_password.placeholder"
                type="password"
                component={Input}
            />
            {errors._global && (
                <InputNote>
                    <FormattedMessage id={errors._global} />
                </InputNote>
            )}
            <Button
                fluid
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
            >
                <FormattedMessage id="changePasswordForm.submit" />
            </Button>
        </form>
    )
}

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    status: PropTypes.object,
}

ChangePasswordForm.defaultProps = {
    errors: {},
    status: {},
}

export const EnhancedForm = withFormik({
    mapPropsToValues: () => ({ password: '', confirm_password: '' }),

    handleSubmit: async (
        values,
        { setSubmitting, setErrors, setStatus, props }
    ) => {
        if (values.password !== values.confirm_password) {
            setErrors({
                confirm_password: 'changePasswordForm.confirm_password.error',
            })
            setSubmitting(false)
        } else {
            try {
                await changePassword(values.password)
                setSubmitting(false)
                props.loginAction()
                setStatus({ success: true })
            } catch (e) {
                setErrors({
                    _global: e.response?.data?.message || 'error.generic',
                })
                setSubmitting(false)
            }
        }
    },
    displayName: 'changePasswordForm',
})(ChangePasswordForm)

export default connect(null, { loginAction: login })(EnhancedForm)
