/* This service is used to login the user registered with an email
to create and login social user (facebook or google)
to handle redirects to the forward urls after successful login
to set cookies with oauth tokens and realm
*/

import qs from 'query-string'
import moment from 'moment'
import { Tracker } from '@gymondo/frontend-core/utils'
import { clearLoginData, setCookiesToken } from '@gymondo/frontend-core/api'
import COOKIE_NAMES from '@gymondo/frontend-core/enums/cookie-names.enum'
import jsCookie from 'js-cookie'
import CRUD from '../../generic/crud/crud'
import URL from '../../../config/url'
import { QUERY_PARAMS } from '../../../config/definitions'
import { trackUserCreated } from '../../../page/generic/checkout-signup/service/signup.service'

const REALM_GYMONDO = 'gymondo'
const OAUTH_URL = process.env.GATSBY_OAUTH_URL
export const REALM_EMAIL = 'email'

export const redirectToForwardUrl = (data) => {
    // GW-773 - forward url
    const { fw } = qs.parse(qs.extract(window.location.href))
    if (fw) {
        const forwardParams = qs.stringify({
            oauth2AccessToken: data.access_token,
            oauth2RefreshToken: data.refresh_token,
        })

        const separator = fw.indexOf('?') !== -1 ? '&' : '?'
        window.location.assign(`${fw}${separator}${forwardParams}`)

        return true
    }

    return false
}

export const updateUser = async (data, trackFirstPartyData) => {
    const userData = await CRUD.get(`${URL.secureUsers}/me`)
    const userId = userData?.data?.id

    if (trackFirstPartyData) {
        trackUserCreated(userData?.data?.username)
    }

    if (userId) {
        return CRUD.patch(`${URL.secureUsers}/${userId}`, data)
    }

    return false
}

export const _login = async (data, realm, allowTrack) => {
    const params = {
        ...data,
        date: moment().format('YYYY-MM-DD[T]HH:mm:ssZZ'),
    }

    const res = await CRUD.post(`${OAUTH_URL}/token`, qs.stringify(params))

    setCookiesToken(res.data, realm)

    if (allowTrack) {
        Tracker.push({
            event: Tracker.EVENTS.TRACKING,
            eventCategory: 'global',
            eventAction: 'login',
            eventLabel: realm === REALM_GYMONDO ? REALM_EMAIL : realm,
        })
    }

    jsCookie.set(COOKIE_NAMES.OAUTH2_REALM, realm, {
        expires: 36500, // 100 years
        path: '/',
    })

    return redirectToForwardUrl(data)
}

export const loginEmail = async ({ username, password }, allowTrack) => {
    const REALM = REALM_EMAIL
    const data = {
        password,
        username,
    }

    return _login(data, REALM, allowTrack)
}

export const requestPassword = async (mail) =>
    CRUD.post(`${URL.usersV2}/reset-password`, {
        username: mail,
    })

const _extractQueryParam = (name) => {
    const queryParams = qs.parse(qs.extract(window.location.href))

    return queryParams[name]
}

export const _getPasswordChangeToken = () => {
    return _extractQueryParam(QUERY_PARAMS.RESET_PASSWORD_CODE)
}

export const _getUsernameFromParams = () => {
    const usernameParam = _extractQueryParam(QUERY_PARAMS.USERNAME)

    return window.atob(usernameParam)
}

export const changePassword = async (password) => {
    const code = _getPasswordChangeToken()
    const email = _getUsernameFromParams()

    await CRUD.post(`${URL.usersV2}/confirm-forgot-password`, {
        username: email,
        password,
        code,
    })

    const loginData = {
        password,
        username: email,
    }

    return loginEmail(loginData, false)
}

/**
 * Logout the user
 *
 * Social apps apis need to be initialized before calling the logout functions
 * Logging out of social apps is necessary otherwise on the next secure endpoint call,
 * the access token will be refreshed
 */
export const logout = async () => {
    try {
        await CRUD.post(`${OAUTH_URL}/logout`)
        // eslint-disable-next-line no-empty
    } catch (e) {}
    clearLoginData()
}
