import * as actions from './affiliate.constants'

const initialState = {
    affiliateData: {},
}
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.SET_AFFILIATE:
            return {
                ...state,
                affiliateData: {
                    ...state.affiliateData,
                    ...payload,
                },
            }
        case actions.REMOVE_AFFILIATE:
            return {
                ...state,
                affiliateData: Object.keys(state.affiliateData).reduce(
                    (acc, curr) =>
                        curr !== payload
                            ? { ...acc, [curr]: state.affiliateData[curr] }
                            : acc,
                    {}
                ),
            }
        default:
            return state
    }
}

export default reducer
