export const NAME = 'landingPrograms'

export const LANDING_PROGRAMS_SUCCESS = `${NAME}/PROGRAMS_SUCCESS`

export const LANDING_PROGRAMS_PENDING = `${NAME}/PROGRAMS_PENDING`

export const LANDING_PROGRAMS_ERROR = `${NAME}/PROGRAMS_ERROR`

export const LANDING_PROGRAMS_SORT = `${NAME}/PROGRAMS_SORT`

export const LANDING_PROGRAMS_FILTERS = `${NAME}/PROGRAMS_FILTERS`

export const LANDING_PROGRAMS_CHANGE_FILTER = `${NAME}/PROGRAMS_CHANGE_FILTER`

export const LANDING_PROGRAMS_RESET_FILTERS = `${NAME}/PROGRAMS_RESET_FILTER`

export const LANDING_PROGRAMS_TOGGLE_DRAWER = `${NAME}/PROGRAMS_TOGGLE_DRAWER`
