/**
 * List of endpoints from the backend
 */
export const baseURLs = {
    backendApi: process.env.GATSBY_API_URL || 'https://www.gymondo.com/api',
    frontendServerApi: `${process.env.GATSBY_FRONTEND_SERVER_API_URL}/v3`,
}

export const baseEndpoints = {
    public: '/v1/public',
    secure: '/v3/secured',
    token: '/token',
    gateway: `${baseURLs.backendApi}/gateway`,
}

/**
 * You can inject parameters on the URL with $number. For example: use '?name=$0&address=$1'
 * Use sequential numbers
 */
const URL = {
    indexListOfPrograms: `${baseURLs.backendApi}${
        baseEndpoints.public
    }/programs?page=0&popularity.dir=desc&q=express%3D%3Dfalse;id%3Dout%3D(230,211,216,217,218,219)&size=${
        process.env.TOTAL_BUILD_PROGRAMS_SIZE || 500
    }&include=usp,reviews&sort=publishTime`, // eslint-disable-line
    workoutsOfProgram: `${baseURLs.backendApi}${baseEndpoints.public}/programs/$0/fitnessvideos?page=$1&size=$2`,
    similarProgramsOfProgram: `${baseURLs.backendApi}${baseEndpoints.public}/programs?page=0&popularity.dir=desc&q=express%3D%3Dfalse;goal%3Din%3D(15);id%3Dout%3D($0)&size=6&sort=popularity`, // eslint-disable-line
    publicTrialInterval: `${baseURLs.backendApi}${baseEndpoints.public}/products`,
    publicCoop: `${baseURLs.backendApi}${baseEndpoints.public}/coop/$0/valid?type=$1`,
    publicNewsletter: `${baseURLs.backendApi}${baseEndpoints.public}/newsletter`,
    publicCampaigns: `${baseURLs.backendApi}${baseEndpoints.public}/campaigns`,
    publicCampaignProducts: `${baseURLs.backendApi}/v1/products`,
    publicPrograms: `${baseURLs.backendApi}${baseEndpoints.public}/programs`,
    publicTokens: `${baseURLs.backendApi}${baseEndpoints.public}/tokens`,
    publicUsers: `${baseURLs.backendApi}${baseEndpoints.public}/users`,
    usersV2: `${baseURLs.backendApi}/v2/users`,
    publicVouchers: `${baseURLs.backendApi}${baseEndpoints.public}/vouchers`,
    secureAffiliate: `${baseURLs.backendApi}${baseEndpoints.secure}/affiliate`,
    secureVouchers: `${baseURLs.backendApi}${baseEndpoints.secure}/vouchers`,
    secureCoop: `${baseURLs.backendApi}${baseEndpoints.secure}/coop/$0/redeem`,
    secureStore: `${baseURLs.backendApi}${baseEndpoints.secure}/store`,
    trialStatus: `${baseURLs.backendApi}/v3/subscriptions/trial-status`,
    secureUsers: `${baseURLs.backendApi}${baseEndpoints.secure}/users`,
    securityContext: `${baseURLs.frontendServerApi}/context`,
    frontendPayment: `${baseURLs.backendApi}/v3/payments`,
    secureSettingsPending: `${baseURLs.backendApi}${baseEndpoints.secure}/settings/pending`,
}

export const replaceUrl = (urlValue, ...values) =>
    values.reduce(
        (agg, val, index) => agg.replace(`$${index}`, val),
        `${urlValue}`
    )

export default URL
