import React from 'react'
import cn from 'classnames'
import { Icon } from '@gymondo/frontend-core/components'
import arrowCircle from '@gymondo/frontend-core/svg/arrow-circle.svg'

import style from './arrow.module.scss'

// eslint-disable-next-line react/prop-types
export const customArrow = classNameEl => ({ onClick, className }) => (
    <div
        onClick={onClick}
        className={cn(style.navArrowWrapper, classNameEl, className)}
    >
        <Icon data={arrowCircle} className={style.navArrowIcon} />
    </div>
)

const ArrowLeft = customArrow(style.navArrowLeft)
const ArrowRight = customArrow(style.navArrowRight)

export { ArrowLeft, ArrowRight }
