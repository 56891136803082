import jsCookie from 'js-cookie'
import _ from 'lodash'
import { crudGeneric, clearLoginData } from '@gymondo/frontend-core/api'
import { COOKIE_NAMES, LOCALES } from '@gymondo/frontend-core/enums'
import { baseEndpoints, baseURLs } from '../../../config/url'
import { languages } from '../../../config/route'
import store from '../../../config/redux/store'
import { LOGOUT } from '../../../service/user/user.constants'

const OAUTH_URL = process.env.GATSBY_OAUTH_URL
const OAUTH_USERNAME = process.env.GATSBY_OAUTH_USERNAME
const OAUTH_PASSWORD = process.env.GATSBY_OAUTH_PASSWORD
const GOOGLE_APP_ID = process.env.GATSBY_GOOGLE_APP_ID
const FACEBOOK_APP_ID = process.env.GATSBY_FACEBOOK_APP_ID
const FACEBOOK_VERSION = process.env.GATSBY_FACEBOOK_VERSION

export const refreshAccessTokenError = () => {
    clearLoginData()
    store.dispatch({ type: LOGOUT })
}

const frontendServerAuthNeeded = url =>
    url.includes(baseURLs.frontendServerApi) && !url.includes('token')

export const isAuthNeeded = url =>
    url.includes(baseEndpoints.secure) || frontendServerAuthNeeded(url)

/**
 * Get language from URL
 */
export const _getLocaleFromURL = () => {
    if (typeof window === 'undefined') {
        return null
    }

    let pathname = _.get(window, 'location.pathname')
    // check if the beggining of the url is /xx/
    pathname =
        pathname &&
        RegExp('\\/.{2}\\/').test(pathname) &&
        pathname.substring(1, 3)
    // check if the language is available

    return languages[pathname] && languages[pathname].locale
}

/**
 * Get the locale of the user from
 * - url (maybe the user cookie says he is Enlish, but the opened the site in German)
 * - cookie,
 * - or fallback
 */
const LANG_FALLBACK = LOCALES.EN_US
export const getUserLocale = () =>
    _getLocaleFromURL() ||
    jsCookie.get(COOKIE_NAMES.USER_LOCALE) ||
    LANG_FALLBACK

const constants = {
    OAUTH_URL,
    TOKEN_ENDPOINT: baseEndpoints.token,
    OAUTH_USERNAME,
    OAUTH_PASSWORD,
    GOOGLE_APP_ID,
    FACEBOOK_APP_ID,
    FACEBOOK_VERSION,
}

const funcs = {
    isAuthNeeded,
    getUserLocale,
    refreshAccessTokenError,
}

export default crudGeneric(constants, funcs)
