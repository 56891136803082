import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import style from './static-layout.module.scss'
import { setConfig } from '../../service/layout'

export const StaticLayout = ({ children, setLayoutConfig }) => {
    useEffect(() => {
        setLayoutConfig({
            textColorVariant: 'black',
            logoVariant: 'ci',
            className: style.whiteBackground,
            footerType: 'STANDARD',
            hideBanner: false,
        })
    }, [setLayoutConfig])

    return <>{children}</>
}

StaticLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    setLayoutConfig: PropTypes.func.isRequired,
}

export default connect(null, { setLayoutConfig: setConfig })(StaticLayout)
