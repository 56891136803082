import { LOCALES, LANGUAGES } from '@gymondo/frontend-core/enums'
import { getUserLocale } from '../../../../../../api/generic/crud/crud'
import visaLogo from '../static/visa.svg'
import mastercardLogo from '../static/mastercard.svg'
import amexLogo from '../static/amex.svg'
import paypalLogo from '../static/paypal.svg'
import sepaLogo from '../static/sepa.svg'
import applePayLogo from '../static/applepay.svg'
import googlePayLogo from '../static/googlepay.svg'
import { getMessage } from '../../../../../../config/intl/wrap-with-intl'
import { PAYMENT_METHODS } from './payment-method.config'

export const MERCHANT_NAME = 'Gymondo GmbH'

export const RESULT_CODES = {
    AUTHENTICATION_FINISHED: 'AuthenticationFinished',
    AUTHORISED: 'Authorised',
    CANCELLED: 'Cancelled',
    CHALLENGE_SHOPPER: 'ChallengeShopper',
    ERROR: 'Error',
    IDENTIFY_SHOPPER: 'IdentifyShopper',
    PENDING: 'Pending',
    PRESENT_TO_SHOPPER: 'PresentToShopper',
    RECEIVED: 'Received',
    REDIRECT_SHOPPER: 'RedirectShopper',
    REFUSED: 'Refused',
}

const AVAILABLE_ENVS = {
    TEST: 'test',
    LIVE: 'live',
}

export const LOCALES_ADYEN = {
    EN_US: 'en-US',
    DE_DE: 'de-DE',
}

const LOCALES_ADYEN_MAPPING = {
    [LOCALES.EN_US]: LOCALES_ADYEN.EN_US,
    [LOCALES.DE_DE]: LOCALES_ADYEN.DE_DE,
}

const LANGUAGES_ADYEN_MAPPING = {
    [LOCALES_ADYEN.EN_US]: LANGUAGES.ENGLISH,
    [LOCALES_ADYEN.DE_DE]: LANGUAGES.GERMAN,
}

export const configuration = {
    locale: LOCALES_ADYEN_MAPPING[getUserLocale()] || LOCALES_ADYEN.EN_US,
    environment:
        process.env.GATSBY_ENV === 'production'
            ? AVAILABLE_ENVS.LIVE
            : AVAILABLE_ENVS.TEST,
    // originKey: process.env.GATSBY_ADYEN_ORIGIN_KEY,
    clientKey: process.env.GATSBY_ADYEN_CLIENT_KEY,
    storePaymentMethod: true,
}

/**
 * Return object with language and translated fields (e.g: {en-US: {field: translation}})
 */
export const getTranslations = (fields) => {
    return Object.values(LOCALES_ADYEN).reduce((localeObject, locale) => {
        return {
            ...localeObject,
            [locale]: Object.keys(fields).reduce((fieldObj, field) => {
                return {
                    ...fieldObj,
                    [field]: getMessage(
                        LANGUAGES_ADYEN_MAPPING[locale] || LANGUAGES.ENGLISH,
                        fields[field]
                    ),
                }
            }, {}),
        }
    }, {})
}

const CARD_TYPES = {
    VISA: { name: 'visa', logo: visaLogo },
    MASTERCARD: { name: 'mc', logo: mastercardLogo },
    AMEX: { name: 'amex', logo: amexLogo },
}

const WALLET_TYPES = {
    PAYPAL: { name: 'paypal', logo: paypalLogo },
}

const BANK_TRANSFER_TYPES = {
    SEPA: { name: 'sepa', logo: sepaLogo },
}

// eslint-disable-next-line no-unused-vars
const MOBILE_PAYMENT_TYPES = {
    APPLE_PAY: {
        name: 'applepay',
        logo: applePayLogo,
    },
    GOOGLE_PAY: {
        name: 'paywithgoogle',
        logo: googlePayLogo,
    },
}

const DEFAULT_EU_PAYMENT_METHODS = [
    {
        name: PAYMENT_METHODS.PAYPAL,
        types: [WALLET_TYPES.PAYPAL],
    },
    {
        name: PAYMENT_METHODS.CARD,
        types: [CARD_TYPES.VISA, CARD_TYPES.MASTERCARD, CARD_TYPES.AMEX],
    },
    {
        name: PAYMENT_METHODS.SEPA,
        types: [BANK_TRANSFER_TYPES.SEPA],
    },
    {
        name: PAYMENT_METHODS.APPLE_PAY,
        types: [MOBILE_PAYMENT_TYPES.APPLE_PAY],
    },
    {
        name: PAYMENT_METHODS.GOOGLE_PAY,
        types: [MOBILE_PAYMENT_TYPES.GOOGLE_PAY],
    },
]

const SEPA_SUPORTTING_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'BL',
    'CH',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GF',
    'GG',
    'GI',
    'GP',
    'GR',
    'HR',
    'HU',
    'IE',
    'IM',
    'IS',
    'IT',
    'JE',
    'LI',
    'LT',
    'LU',
    'LV',
    'MC',
    'MF',
    'MQ',
    'MT',
    'NL',
    'NO',
    'PL',
    'PM',
    'PT',
    'RE',
    'RO',
    'SE',
    'SI',
    'SK',
    'SM',
    'YT',
]

const SEPA_COUNTRIES_PAYMENT_METHODS = SEPA_SUPORTTING_COUNTRIES.reduce(
    (previousValue, current) => ({
        ...previousValue,
        [current]: DEFAULT_EU_PAYMENT_METHODS,
    }),
    {}
)

export const REGION_PAYMENT_MAP = {
    ...SEPA_COUNTRIES_PAYMENT_METHODS,
    US: [
        {
            name: PAYMENT_METHODS.PAYPAL,
            types: [WALLET_TYPES.PAYPAL],
        },
        {
            name: PAYMENT_METHODS.APPLE_PAY,
            types: [MOBILE_PAYMENT_TYPES.APPLE_PAY],
        },
        {
            name: PAYMENT_METHODS.CARD,
            types: [CARD_TYPES.VISA, CARD_TYPES.MASTERCARD, CARD_TYPES.AMEX],
        },
        {
            name: PAYMENT_METHODS.GOOGLE_PAY,
            types: [MOBILE_PAYMENT_TYPES.GOOGLE_PAY],
        },
    ],
}

export const DEFAULT_PAYMENT_METHODS = [
    {
        name: PAYMENT_METHODS.PAYPAL,
        types: [WALLET_TYPES.PAYPAL],
    },
    {
        name: PAYMENT_METHODS.APPLE_PAY,
        types: [MOBILE_PAYMENT_TYPES.APPLE_PAY],
    },
    {
        name: PAYMENT_METHODS.CARD,
        types: [CARD_TYPES.VISA, CARD_TYPES.MASTERCARD, CARD_TYPES.AMEX],
    },
    {
        name: PAYMENT_METHODS.GOOGLE_PAY,
        types: [MOBILE_PAYMENT_TYPES.GOOGLE_PAY],
    },
]

export const REFUSAL_REASONS = {
    THREE_D_SECURE_AUTH_FAILED: '3d-secure: Authentication failed',
    THREE_D_NOT_AUTHENTICATED: '3D Not Authenticated',
    ACQUIRER_FRAUD: 'Acquirer Fraud',
    AUTHENTICATION_REQUIRED: 'Authentication Required',
    BLOCKED_CARD: 'Blocked Card',
    CANCELLED: 'Cancelled',
    CVC_DECLINED: 'CVC Declined',
    REFUSED: 'Refused',
    DECLINED_NON_GENERIC: 'Declined Non Generic',
    ACQUIRER_ERROR: 'Acquirer Error',
    EXPIRED_CARD: 'Expired Card',
    FRAUD: 'FRAUD',
    FRAUD_CANCELLED: 'FRAUD-CANCELLED',
    INVALID_AMOUNT: 'Invalid Amount',
    INVALID_CARD_NUMBER: 'Invalid Card Number',
    INVALID_PIN: 'Invalid Pin',
    ISSUER_SUSPECTED_FRAUD: 'Issuer Suspected Fraud',
    ISSUER_UNAVAILABLE: 'Issuer Unavailable',
    NOT_ENOUGH_BALANCE: 'Not enough balance',
    NOT_SUBMITTED: 'Not Submitted',
    NOT_SUPPORTED: 'Not supported',
    PENDING: 'Pending',
    PIN_TRIES_EXCEEDED: 'Pin tries exceeded',
    PIN_VALIDATION_NOT_POSSIBLE: 'Pin validation not possible',
    REFERRAL: 'Referral',
    RESTRICTED_CARD: 'Restricted Card',
    REVOCATION_OF_AUTH: 'Revocation Of Auth',
    SHOPPER_CANCELLED: 'Shopper Cancelled',
    WITHDRAWAL_COUNT_EXCEEDED: 'Withdrawal count exceeded',
    WITHDRAWAL_AMOUNT_EXCEEDED: 'Withdrawal amount exceeded',
    TRANSACTION_NOT_PERMITTED: 'Transaction Not Permitted',
    UNKNOWN: 'Unknown',
}

export const HIGHLIGHTED_PAYMENT_METHODS = [PAYMENT_METHODS.PAYPAL]
