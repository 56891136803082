import { isEU } from '@gymondo/frontend-core/utils/intl'
import { COOKIE_CONSENT_KEYS } from '@gymondo/frontend-core/enums'
import { getConsentValue } from '@gymondo/frontend-core/components/cookie-consent/util'

import {
    SET_INITIAL_STATE,
    SET_COOKIE_CONSENT_STATE,
    SET_CONSENTS,
    SET_SOCIAL_LOGIN_CONSENT,
    SET_CONSENT_TIER_CHOSEN,
} from './cookie-consent.constants'

const isEURegion = isEU()

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
    payload: {
        consentChosen:
            getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_MANDATORY_OPTIN) ||
            !isEURegion,
        state: null,
        isEU: isEURegion,
        hasMarketingConsent:
            getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN) ||
            !isEURegion,
        hasAnalyticsConsent:
            getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_ANALYTICS_OPTIN) ||
            !isEURegion,
        hasSocialLoginConsent:
            getConsentValue(
                COOKIE_CONSENT_KEYS.GYM_CONSENT_SOCIAL_LOGIN_OPTIN
            ) || !isEURegion,
        hasFirstPartyConsent:
            getConsentValue(
                COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN
            ) || !isEURegion,
    },
})

export const setCookieConsentState = (state) => ({
    type: SET_COOKIE_CONSENT_STATE,
    payload: state,
})

export const setSocialLoginConsent = () => ({
    type: SET_SOCIAL_LOGIN_CONSENT,
    payload: true,
})

export const setStoredConsents = () => ({
    type: SET_CONSENTS,
    payload: {
        hasMarketingConsent:
            getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_MARKETING_OPTIN) ||
            !isEURegion,
        hasAnalyticsConsent:
            getConsentValue(COOKIE_CONSENT_KEYS.GYM_CONSENT_ANALYTICS_OPTIN) ||
            !isEURegion,
        hasSocialLoginConsent:
            getConsentValue(
                COOKIE_CONSENT_KEYS.GYM_CONSENT_SOCIAL_LOGIN_OPTIN
            ) || !isEURegion,
        hasFirstPartyConsent:
            getConsentValue(
                COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN
            ) || !isEURegion,
    },
})

export const setConsentTierChosen = () => ({
    type: SET_CONSENT_TIER_CHOSEN,
    payload: true,
})
