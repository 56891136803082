// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-generic-404-404-js": () => import("./../../../src/page/generic/404/404.js" /* webpackChunkName: "component---src-page-generic-404-404-js" */),
  "component---src-page-generic-checkout-checkout-js": () => import("./../../../src/page/generic/checkout/checkout.js" /* webpackChunkName: "component---src-page-generic-checkout-checkout-js" */),
  "component---src-page-generic-checkout-package-checkout-package-js": () => import("./../../../src/page/generic/checkout-package/checkout-package.js" /* webpackChunkName: "component---src-page-generic-checkout-package-checkout-package-js" */),
  "component---src-page-generic-checkout-signup-checkout-signup-js": () => import("./../../../src/page/generic/checkout-signup/checkout-signup.js" /* webpackChunkName: "component---src-page-generic-checkout-signup-checkout-signup-js" */),
  "component---src-page-generic-community-community-js": () => import("./../../../src/page/generic/community/community.js" /* webpackChunkName: "component---src-page-generic-community-community-js" */),
  "component---src-page-generic-cookie-policy-cookie-policy-js": () => import("./../../../src/page/generic/cookie-policy/cookie-policy.js" /* webpackChunkName: "component---src-page-generic-cookie-policy-cookie-policy-js" */),
  "component---src-page-generic-coop-coop-js": () => import("./../../../src/page/generic/coop/coop.js" /* webpackChunkName: "component---src-page-generic-coop-coop-js" */),
  "component---src-page-generic-home-home-js": () => import("./../../../src/page/generic/home/home.js" /* webpackChunkName: "component---src-page-generic-home-home-js" */),
  "component---src-page-generic-how-it-works-how-it-works-js": () => import("./../../../src/page/generic/how-it-works/how-it-works.js" /* webpackChunkName: "component---src-page-generic-how-it-works-how-it-works-js" */),
  "component---src-page-generic-imprint-imprint-js": () => import("./../../../src/page/generic/imprint/imprint.js" /* webpackChunkName: "component---src-page-generic-imprint-imprint-js" */),
  "component---src-page-generic-newsletter-newsletter-js": () => import("./../../../src/page/generic/newsletter/newsletter.js" /* webpackChunkName: "component---src-page-generic-newsletter-newsletter-js" */),
  "component---src-page-generic-partner-partner-js": () => import("./../../../src/page/generic/partner/partner.js" /* webpackChunkName: "component---src-page-generic-partner-partner-js" */),
  "component---src-page-generic-press-press-js": () => import("./../../../src/page/generic/press/press.js" /* webpackChunkName: "component---src-page-generic-press-press-js" */),
  "component---src-page-generic-privacy-privacy-js": () => import("./../../../src/page/generic/privacy/privacy.js" /* webpackChunkName: "component---src-page-generic-privacy-privacy-js" */),
  "component---src-page-generic-program-program-js": () => import("./../../../src/page/generic/program/program.js" /* webpackChunkName: "component---src-page-generic-program-program-js" */),
  "component---src-page-generic-referral-referral-js": () => import("./../../../src/page/generic/referral/referral.js" /* webpackChunkName: "component---src-page-generic-referral-referral-js" */),
  "component---src-page-generic-success-success-js": () => import("./../../../src/page/generic/success/success.js" /* webpackChunkName: "component---src-page-generic-success-success-js" */),
  "component---src-page-generic-term-and-condition-term-and-condition-js": () => import("./../../../src/page/generic/term-and-condition/term-and-condition.js" /* webpackChunkName: "component---src-page-generic-term-and-condition-term-and-condition-js" */),
  "component---src-page-generic-tvsignup-tvsignup-js": () => import("./../../../src/page/generic/tvsignup/tvsignup.js" /* webpackChunkName: "component---src-page-generic-tvsignup-tvsignup-js" */)
}

