export const NAME = 'layout'

export const SET_HAS_FLOATING_CTA = `${NAME}/SET_HAS_FLOATING_CTA`

export const SET_TEXT_COLOR_VARIANT = `${NAME}/SET_TEXT_COLOR_VARIANT`

export const SET_CLASS_NAME = `${NAME}/SET_CLASS_NAME`

export const SET_LOGO_VARIANT = `${NAME}/SET_LOGO_VARIANT`

export const SET_LOGO_CLASSNAME = `${NAME}/SET_LOGO_CLASSNAME`

export const SET_SHOW_LOGOUT = `${NAME}/SET_SHOW_LOGOUT`

export const SET_HIDE_ON_MOBILE = `${NAME}/SET_HIDE_ON_MOBILE`

export const SET_ALWAYS_TOP = `${NAME}/SET_ALWAYS_TOP`

export const SET_HIDE_NAVIGATION = `${NAME}/SET_HIDE_NAVIGATION`

export const SET_SHOW_USER_NAME = `${NAME}/SET_SHOW_USER_NAME`

export const SET_FOOTER_TYPE = `${NAME}/SET_FOOTER_TYPE`

export const SET_HIDE_BANNER = `${NAME}/SET_HIDE_BANNER`

export const SET_HIDE_LOGO = `${NAME}/SET_HIDE_LOGO`
