import * as actions from './session.constants'
import { createSession } from '../../page/generic/checkout-signup/component/payment/payment.service'
import status from '../../api/generic/redux/status.enum'

export const create = (paymentMethod) => async (dispatch, getState) => {
    dispatch({ type: actions.PENDING })
    const { user } = getState()
    const { selectedProduct, channel, signupAction } = user
    if (user.status === status.OK) {
        try {
            const result = await createSession(
                selectedProduct,
                channel && Number.parseInt(channel.id, 10),
                signupAction,
                paymentMethod
            )
            dispatch({ type: actions.SUCCESS, payload: result })
        } catch (e) {
            dispatch({
                type: actions.ERROR,
                payload: {
                    message: e.response?.data?.message || 'error.generic',
                    reason: e.response?.data?.reason,
                },
            })
        }
    }
}

export const reset = () => ({ type: actions.SUCCESS, payload: null })
