import { Tracker } from '@gymondo/frontend-core/utils'
import CRUD from '../../generic/crud/crud'
import URL from '../../../config/url'
import { REALM_EMAIL } from '../login/login.service'

export const createUser = async payload => {
    const response = await CRUD.post(URL.usersV2, payload)

    Tracker.push({
        event: Tracker.EVENTS.TRACKING,
        eventCategory: 'global',
        eventAction: 'sign_up',
        eventLabel: REALM_EMAIL,
    })

    return response
}
