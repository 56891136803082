import {
    SET_LOGIN_MODAL_CLOSED,
    SET_LOGIN_MODAL_OPEN,
    SET_LOGIN_MODAL_TYPE,
} from './page-header.constants'

const initialState = {
    loginModalOpen: false,
    loginModalType: 'login',
    loginModalForceOpen: false,
}
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_LOGIN_MODAL_OPEN:
            return {
                ...state,
                loginModalOpen: true,
                loginModalForceOpen: payload,
            }
        case SET_LOGIN_MODAL_CLOSED:
            return {
                ...state,
                loginModalOpen: false,
                loginModalForceOpen: false,
            }
        case SET_LOGIN_MODAL_TYPE:
            return {
                ...state,
                loginModalType: payload,
            }
        default:
            return state
    }
}

export default reducer
