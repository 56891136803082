import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from '@gymondo/frontend-core/components'
import { mediaXsOnly } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'
import { layout as layoutService } from '../../service'

export const SimpleCta = ({ setFloatingCta, fixedXS, children, ...rest }) => {
    const matches = useMediaQuery(mediaXsOnly)
    const isFloating = matches && fixedXS

    useEffect(() => {
        setFloatingCta(isFloating)

        return () => setFloatingCta(false)
    }, [isFloating, setFloatingCta])

    return (
        <Button flex={false} fixed={isFloating} {...rest}>
            {children}
        </Button>
    )
}

SimpleCta.propTypes = {
    fixedXS: PropTypes.bool,
    setFloatingCta: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

SimpleCta.defaultProps = {
    fixedXS: true,
}

export default connect(null, {
    setFloatingCta: layoutService.setHasFloatingCta,
})(SimpleCta)
