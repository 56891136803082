import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { injectIntl, FormattedMessage } from 'react-intl'

import membersIcon from '@gymondo/frontend-core/svg/community-people.svg'

import { Button, Icon } from '@gymondo/frontend-core/components'

import { BackgroundImage } from '..'

import style from './social-card.module.scss'

const renderFacebook = (item) => (
    <div>
        <div className={cn('gym-h5', 'gym-h', style.title)}>
            {item.icon ? (
                <Icon data={item.icon} />
            ) : (
                <FormattedMessage id={item.title} />
            )}
        </div>
        <div className={style.members}>
            <Icon
                data={membersIcon}
                className={cn('svg-text__svg', style.icon)}
            />
            {item.members && <FormattedMessage id={item.members} />}
        </div>
        <div className="btn--flex">
            <Button
                type="facebook"
                className={cn(
                    'btn--small',
                    style.btn,
                    'btn-gym--no-social-icon'
                )}
            >
                <FormattedMessage id="community.btn.facebook" />
            </Button>
        </div>
    </div>
)

const renderInstagram = (item) => (
    <div className={style.text}>
        <FormattedMessage id={item.text} />
    </div>
)

export const SocialCard = ({
    item,
    isFacebook,
    intl,
    className,
    wrapperClassName,
    ...rest
}) => (
    <div className={cn('col-sm-4 col-xs-12', wrapperClassName)}>
        <a
            href={intl.formatMessage({ id: item.link })}
            className={cn(style.card, 'link--none')}
            target="_blank"
            rel="noopener noreferrer"
            {...rest}
        >
            <div className={cn(style.header, className)}>
                <BackgroundImage overlay={item.overlay} fluid={item.img}>
                    {!item.image && item.title && (
                        <span className="gym-h6">
                            <FormattedMessage id={item.title} />
                        </span>
                    )}
                    {item.image && (
                        <img
                            className={style.titleImage}
                            src={item.image}
                            alt={
                                item.title &&
                                intl.formatMessage({ id: item.title })
                            }
                        />
                    )}
                </BackgroundImage>
            </div>
            {isFacebook && renderFacebook(item)}
            {!isFacebook && renderInstagram(item)}
        </a>
    </div>
)

SocialCard.propTypes = {
    item: PropTypes.object.isRequired,
    isFacebook: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
}

SocialCard.defaultProps = {
    className: null,
    wrapperClassName: null,
}

export default injectIntl(SocialCard)
