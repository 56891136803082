import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Icon } from '@gymondo/frontend-core/components'
import closeSvg from '@gymondo/frontend-core/svg/u-close.svg'

import style from './modal-title.module.scss'

const ModalTitle = ({ formattedMessageId, onCloseClick, forceOpen }) => (
    <div className={style.titleWrapper}>
        <FormattedMessage id={formattedMessageId} />
        {!forceOpen && (
            <Icon
                data={closeSvg}
                className={style.titleIcon}
                onClick={onCloseClick}
            />
        )}
    </div>
)

ModalTitle.propTypes = {
    formattedMessageId: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    forceOpen: PropTypes.bool,
}

ModalTitle.defaultProps = {
    forceOpen: false,
}

export default ModalTitle
