import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect, useSelector } from 'react-redux'
import qs from 'query-string'
import { SessionStorage } from '@gymondo/frontend-core/utils'
import { SESSION_STORAGE_KEYS } from '@gymondo/frontend-core/enums'
import PageHeader, { LOGO_VARIANTS } from '../page-header'
import PageFooter from '../page-footer'
import SignupFooter from '../signup-footer'
import style from './layout.module.scss'
import { QUERY_PARAMS } from '../../config/definitions'

const FOOTER_TYPES_MAP = {
    HIDDEN: () => <></>,
    STANDARD: PageFooter,
    SIMPLE: SignupFooter,
}

export const Layout = ({
    className,
    children,
    textColorVariant,
    showLogout,
    hideOnMobile,
    alwaysTop,
    hideNavigation,
    showUserName,
    footerType,
    logoClassName,
    logoVariant,
    hideBanner,
    hideLogo,
    contextFooterType,
}) => {
    const FooterComponent = FOOTER_TYPES_MAP[contextFooterType || footerType]
    const { hasMarketingConsent } = useSelector((state) => state.cookieConsent)
    useLayoutEffect(() => {
        const queryParams = qs.parse(window?.location.search)
        const voucher = queryParams[QUERY_PARAMS.VOUCHER]
        if (voucher) {
            SessionStorage.set(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER, {
                code: voucher,
            })
        }

        // eslint-disable-next-line camelcase
        const utmSource = queryParams?.utm_source
        if (utmSource && hasMarketingConsent) {
            SessionStorage.set('optimize_utm_source', utmSource)
        }

        // eslint-disable-next-line camelcase
        const utmMedium = queryParams?.utm_medium
        if (utmMedium && hasMarketingConsent) {
            SessionStorage.set('optimize_utm_medium', utmMedium)
        }

        const gclid = queryParams?.gclid
        if (gclid && hasMarketingConsent) {
            SessionStorage.set('optimize_gclid', gclid)
        }
    }, [hasMarketingConsent])

    return (
        <div className={cn(style.layoutWrapper, className)}>
            <PageHeader
                showLogout={showLogout}
                hideOnMobile={hideOnMobile}
                textColorVariant={textColorVariant}
                alwaysTop={alwaysTop}
                hideNavigation={hideNavigation}
                showUserName={showUserName}
                logoClassName={logoClassName}
                logoVariant={logoVariant}
                hideBanner={hideBanner}
                hideLogo={hideLogo}
            />
            {children}
            <FooterComponent />
        </div>
    )
}

Layout.defaultProps = {
    className: '',
    textColorVariant: 'white',
    showLogout: false,
    hideOnMobile: false,
    alwaysTop: false,
    hideNavigation: false,
    showUserName: false,
    footerType: 'STANDARD',
    logoClassName: null,
    logoVariant: LOGO_VARIANTS.GYM_10_YEARS,
    hideBanner: true,
    hideLogo: false,
    contextFooterType: null,
}

Layout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    textColorVariant: PropTypes.oneOf(['white', 'black']),
    showLogout: PropTypes.bool,
    hideOnMobile: PropTypes.bool,
    alwaysTop: PropTypes.bool,
    hideNavigation: PropTypes.bool,
    showUserName: PropTypes.bool,
    footerType: PropTypes.oneOf(['STANDARD', 'SIMPLE']),
    logoClassName: PropTypes.string,
    logoVariant: PropTypes.oneOf([
        'white',
        LOGO_VARIANTS.GYM_10_YEARS,
        LOGO_VARIANTS.CI,
        LOGO_VARIANTS.DEFAULT,
        LOGO_VARIANTS.PRIDE,
    ]),
    hideBanner: PropTypes.bool,
    hideLogo: PropTypes.bool,
    contextFooterType: PropTypes.string,
}

export const mapStateToProps = ({ layout }) => ({
    ...layout,
})

export default connect(mapStateToProps)(Layout)
