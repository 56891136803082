import qs from 'query-string'
import { Tracker } from '@gymondo/frontend-core/utils'
import { PATHS, languages, urls, ROUTES_ID } from '../config/route'
import { QUERY_PARAMS } from '../config/definitions'

export const redirectToTimeline = () => {
    window.location.assign(PATHS.timeline)
}

export const redirectToOnboarding = () => {
    window.location.assign(PATHS.onboarding)
}

export const trackVirtualPageView = (location, isGeneric) => {
    const { pathname, search } = location
    const locale = pathname.substring(0, 4)

    const currentLocale = Object.keys(languages).find(
        lang => `/${lang}/` === locale && lang
    )

    if (currentLocale) {
        Tracker.push({
            event: Tracker.EVENTS.SET_CUSTOM_DIMENSION,
            name: 'language',
            value: currentLocale,
        })
    }

    let virtualPageURL = currentLocale ? pathname.substring(3) : pathname
    // for subscribe page, use own tracking
    // TODO: if there is another case, make it generic
    if (currentLocale && virtualPageURL) {
        const subscribePath = urls[ROUTES_ID.CHECKOUT_SUBSCRIBE].path
        if (virtualPageURL.startsWith(subscribePath)) {
            if (isGeneric) {
                return
            }

            const queryParam = qs.parse(search)[
                QUERY_PARAMS.SELECTED_PAYMENT_METHOD
            ]
            if (queryParam) {
                virtualPageURL = `${subscribePath}?${QUERY_PARAMS.SELECTED_PAYMENT_METHOD}=${queryParam}`
            }
        }
    }

    Tracker.push({
        event: Tracker.EVENTS.VIRTUAL_PAGEVIEW,
        virtualPageURL,
    })
}
