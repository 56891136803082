import { SessionStorage, Tracker } from '@gymondo/frontend-core/utils'
import {
    SESSION_STORAGE_KEYS,
    LOCAL_STORAGE_KEYS,
    COOKIE_CONSENT_KEYS,
} from '@gymondo/frontend-core/enums'
import { getConsentValue } from '@gymondo/frontend-core/components/cookie-consent/util'
import qs from 'query-string'
import CRUD from '../../../../../api/generic/crud/crud'
import URL from '../../../../../config/url'
import {
    AFFILIATE_PARTNERS,
    SIGNUP_ACTIONS,
} from '../../../../../config/definitions'
import {
    getAffiliate,
    removeAffiliate,
} from '../../../../../service/affiliate/affiliate.action'

const TRACKER_ID = process.env.GATSBY_GA_TRACKER_ID

export const sendAffiliateTracking = async (dispatch) => {
    const zanoxPid = await dispatch(
        getAffiliate(LOCAL_STORAGE_KEYS.SIGNUP_ZANOX)
    )
    const sovendusPid = await dispatch(
        getAffiliate(LOCAL_STORAGE_KEYS.SIGNUP_SOVENDUS)
    )

    const affiliateType = zanoxPid
        ? AFFILIATE_PARTNERS.ZANOX
        : AFFILIATE_PARTNERS.SOVENDUS
    const pid = zanoxPid || sovendusPid
    if (pid) {
        await CRUD.post(
            URL.secureAffiliate,
            { type: affiliateType, affiliateId: pid },
            undefined,
            true
        ).catch(() => null)
        dispatch(removeAffiliate())
    }
}

/**
 * Generate property value for GA Ecommerce props
 * @param {*} value
 * @param {*} alternative
 */
export const _formatParam = (value, alternative) =>
    (value || alternative).toString()

/**
 * Build an GA Ecommerce object
 * @param {Number} userId
 * @param {Object} boughtProduct
 */
export const _buildEcommerceItem = (userId, boughtProduct) => ({
    id: _formatParam(userId, `no-user-${new Date().toISOString()}`),
    sku: _formatParam(
        boughtProduct && boughtProduct.articleNumber,
        'gymondo-program'
    ),
    category: 'paying',
    price: _formatParam(
        boughtProduct && (boughtProduct.discountedPrice || boughtProduct.price),
        0.0
    ), // unit price
    quantity: '1',
    name: _formatParam(
        boughtProduct && boughtProduct.articleNumber,
        'gymondo-program'
    ),
})

export const _buildEcommerceTransaction = (userId, boughtProduct) => ({
    id: _formatParam(userId, `no-user-${new Date().toISOString()}`),
    affiliation: 'gymondo',
    revenue: _formatParam(
        boughtProduct && (boughtProduct.discountedPrice || boughtProduct.price),
        0.0
    ), // unit price
    shipping: '0',
    tax: '0',
})

const timeoutPromise = (func, tmo = 1000) =>
    new Promise((resolve) => {
        setTimeout(resolve, tmo)
        func(resolve)
    })

/**
 * Send the data to Google Analytics
 * @param {Number} userId
 * @param {Boolean} isTrial
 * @param {Object} boughtProduct
 * @param {Object} channel
 */
export const sendGaTrackingData = (userId, boughtProduct) => {
    if (!window.ga) {
        return null
    }

    window.ga('create', {
        trackingId: TRACKER_ID,
        cookieDomain: 'auto',
        name: 'gymondo',
    })
    window.ga('gymondo.require', 'ecommerce')
    const trackingPromises = [
        timeoutPromise((resolve) => {
            window.ga('gymondo.ecommerce:addTransaction', {
                ..._buildEcommerceTransaction(userId, boughtProduct),
                hitCallback: resolve,
            })
        }),
        timeoutPromise((resolve) => {
            window.ga('gymondo.ecommerce:addItem', {
                ..._buildEcommerceItem(userId, boughtProduct),
                hitCallback: resolve,
            })
        }),
    ]

    window.ga('gymondo.ecommerce:send')

    return Promise.all(trackingPromises)
}

/**
 * Send tracking data to GTM
 * @param {Number} userId
 * @param {Boolean} isTrial
 * @param {Object} boughtProduct
 * @param {Object} params
 */
export const sendGtmTrackingData = (
    userId,
    isTrial,
    boughtProduct,
    params,
    email
) => {
    const trackingPromises = [
        timeoutPromise((resolve) => {
            Tracker.push({
                event: 'registrationSuccess',
                pageCategory: 'success',
                user_id: userId,
                category: isTrial ? 'trial' : 'return',
                productPaying: boughtProduct
                    ? boughtProduct.articleNumber
                    : null,
                price: boughtProduct ? boughtProduct.price : null,
                pspReference: params.pspReference,
                isTrial,
                interval: boughtProduct ? boughtProduct.interval : null,
                sessionChannel: SessionStorage.get(
                    SESSION_STORAGE_KEYS.CHANNEL
                ),
                eventCallback: resolve,
                email: getConsentValue(
                    COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN
                )
                    ? email
                    : null,
            })
        }),
        timeoutPromise((resolve) => {
            Tracker.push({
                event: Tracker.EVENTS.TRACKING,
                eventCategory: 'checkout',
                eventAction: 'purchase_success',
                eventLabel: boughtProduct ? boughtProduct.articleNumber : null,
                eventValue: boughtProduct
                    ? boughtProduct.discountedPrice || boughtProduct.price
                    : null,
                eventCallback: resolve,
            })
        }),
    ]

    return Promise.all(trackingPromises)
}

let retryCounter = 0
export const sendTrackingData = async (user) => {
    if (
        user.signupAction === SIGNUP_ACTIONS.PAYMENT ||
        user.signupAction === SIGNUP_ACTIONS.REACTIVATION
    ) {
        return null
    }
    const retryLimit = 6
    const retryTimeoutVal = 1000

    if (typeof window.ga !== 'undefined' && window.ga.loaded) {
        await Promise.all([
            sendGaTrackingData(
                user.id,
                user.isTrial,
                user.selectedProduct,
                user.channel
            ),
            sendGtmTrackingData(
                user.id,
                user.isTrial,
                user.selectedProduct,
                qs.parse(window.location.search),
                user.userName
            ),
        ])
        retryCounter = 0
    } else if (retryCounter++ < retryLimit) {
        await new Promise((resolve) => {
            setTimeout(async () => {
                await sendTrackingData(user)
                resolve()
            }, retryTimeoutVal)
        })
    }

    return true
}
