import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import moment from 'moment'
import jsCookie from 'js-cookie'
import { getIntlLocaleRegionTag } from '@gymondo/frontend-core/utils'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'
import { useSelector, useDispatch } from 'react-redux'
import { CookieConsent } from '@gymondo/frontend-core/components'
import { loadAffiliateIntoLocalStorage } from '../../service/affiliate/affiliate.action'
import { setGymondoShopCookieConsentString } from '../../service/cookie-consent/cookie-consent.util'
import {
    setInitialState,
    setCookieConsentState,
    setStoredConsents,
    setConsentTierChosen,
} from '../../service/cookie-consent/cookie-consent.action'
import { BuildInfo, Layout, SEO } from '../../component'

// Locale data
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/en'

import PageContext from './page-context.context'
import { getAllPageUrls } from '../route'

import de from '../../i18n/.build/.de.json'
import en from '../../i18n/.build/.en.json'

const messages = {
    de,
    en,
}

export const getMessage = (lang, field) => messages[lang]?.[field]

export const LayoutI18n = ({ element, props: { pageContext } }) => {
    const {
        locale: language,
        page,
        view,
        seoSpecific,
        views,
        redirectURL,
    } = pageContext
    const msgs = messages[language]
    moment.locale(language)
    const region = jsCookie.get(COOKIE_NAMES.USER_REGION)
    const shouldNotAddLayer = pageContext.noHeader || !language

    const pageUrls = getAllPageUrls(page, view, views)

    const dispatch = useDispatch()

    const { state: cookieConsentState } = useSelector(
        (state) => state.cookieConsent
    )

    useEffect(() => {
        dispatch(setInitialState())
    }, [dispatch])

    return (
        <IntlProvider
            locale={getIntlLocaleRegionTag(language, region)}
            messages={messages[language]}
            onError={() => null}
        >
            <PageContext.Provider value={pageContext}>
                <SEO
                    seoSpecific={seoSpecific}
                    messages={msgs}
                    pageName={page}
                    lang={language}
                    pageUrls={pageUrls}
                    redirectURL={redirectURL}
                />
                <div>
                    <CookieConsent
                        locale={language}
                        state={cookieConsentState}
                        onConsentGiven={(trackingAllowed) => {
                            if (trackingAllowed) {
                                dispatch(setStoredConsents())
                            }
                            dispatch(setCookieConsentState(null))
                            dispatch(loadAffiliateIntoLocalStorage())
                            dispatch(setConsentTierChosen())
                            setGymondoShopCookieConsentString()
                        }}
                        GTMId={process.env.GATSBY_GTM_ID}
                    />
                </div>
                {shouldNotAddLayer ? (
                    <div>{element}</div>
                ) : (
                    <Layout contextFooterType={pageContext.footerType}>
                        {element}
                    </Layout>
                )}
                {process.env.GATSBY_ENV !== 'production' && <BuildInfo />}
            </PageContext.Provider>
        </IntlProvider>
    )
}

const defaultProps = {
    props: { pageContext: {} },
}

const propTypes = {
    element: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    props: PropTypes.shape({
        pageContext: PropTypes.shape({
            locale: PropTypes.string.isRequired,
            footerType: PropTypes.string,
            redirectURL: PropTypes.string,
            seoSpecific: PropTypes.object,
            page: PropTypes.string,
            noHeader: PropTypes.bool,
            showCookieSettings: PropTypes.bool,
            view: PropTypes.string,
            views: PropTypes.object,
        }),
    }),
}

LayoutI18n.defaultProps = defaultProps
LayoutI18n.propTypes = propTypes

const WrapWithIntl = ({ element, props }) => (
    <LayoutI18n element={element} props={props} />
)

WrapWithIntl.defaultProps = defaultProps
WrapWithIntl.propTypes = propTypes

export default WrapWithIntl
