import * as actions from './session.constants'
import * as userActions from '../user/user.constants'
import status from '../../api/generic/redux/status.enum'

const initialState = {
    session: null,
    status: status.OK,
}
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.PENDING:
            return {
                ...state,
                status: status.PENDING,
            }
        case actions.SUCCESS:
            return {
                ...state,
                session: payload,
                error: null,
                status: status.OK,
            }
        case actions.ERROR:
            return {
                ...state,
                session: null,
                error: payload.message,
                errorReason: payload.reason,
                status: status.ERROR,
            }
        case userActions.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

export default reducer
