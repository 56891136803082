import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'

import { FormattedMessage } from 'react-intl'
import { LinkLocalized } from '..'
import LanguageSwitch from '../page-footer/component/language-switch/language-switch'
import PageContext from '../../config/intl/page-context.context'
import { ROUTES_ID } from '../../config/route'

import style from './signup-footer.module.scss'

const TRANSLATION_KEY = 'signup.footer.'

export const SignupFooter = ({ className, hasFloatingCta }) => {
    const pageContext = useContext(PageContext)
    const { view } = pageContext

    return (
        <footer
            className={cn(
                style.signupFooter,
                hasFloatingCta && style.hasFloatingCta,
                className
            )}
        >
            <div className={style.footerWrapper}>
                <div>
                    <LanguageSwitch isSignup />
                    <div className={style.footerText}>
                        <FormattedMessage id={`${TRANSLATION_KEY}part1`} />
                        <LinkLocalized
                            className="link"
                            to={ROUTES_ID.TERMS_AND_CONDITIONS}
                            title={`${TRANSLATION_KEY}terms`}
                            view={view}
                        >
                            <FormattedMessage id={`${TRANSLATION_KEY}terms`} />
                        </LinkLocalized>
                        <FormattedMessage id={`${TRANSLATION_KEY}part2`} />
                        <LinkLocalized
                            className="link"
                            to={ROUTES_ID.PRIVACY}
                            title={`${TRANSLATION_KEY}privacy`}
                            view={view}
                        >
                            <FormattedMessage
                                id={`${TRANSLATION_KEY}privacy`}
                            />
                        </LinkLocalized>
                        <FormattedMessage id={`${TRANSLATION_KEY}part3`} />
                        <LinkLocalized
                            className="link"
                            to={ROUTES_ID.IMPRINT}
                            title={`${TRANSLATION_KEY}imprint`}
                            view={view}
                        >
                            <FormattedMessage
                                id={`${TRANSLATION_KEY}imprint`}
                            />
                        </LinkLocalized>
                    </div>
                </div>
            </div>
        </footer>
    )
}

SignupFooter.propTypes = {
    className: PropTypes.string,
    hasFloatingCta: PropTypes.bool.isRequired,
}

SignupFooter.defaultProps = {
    className: '',
}

export const mapStateToProps = ({ layout }) => ({
    hasFloatingCta: layout.hasFloatingCta,
})

export default connect(mapStateToProps)(SignupFooter)
