import React from 'react'
import cn from 'classnames'
import { Icon } from '@gymondo/frontend-core/components'
import logoBlack from '@gymondo/frontend-core/svg/logo-black.svg'

import { LOGO_VARIANTS } from '../utils'
import logoPrideMonth from './assets/pride-month-logo.svg'
import gym10YearsWhiteDE from './assets/gym-10-years-white-de.svg'
import gym10YearsCoralDE from './assets/gym-10-years-coral-de.svg'
import gym10YearsWhiteEN from './assets/gym-10-years-white-en.svg'
import gym10YearsCoralEN from './assets/gym-10-years-coral-en.svg'

import style from './logo.module.scss'

export const ciLogo = <Icon data={logoBlack} className="gym-logo__svg--ci" />
export const prideLogo = (
    <Icon data={logoPrideMonth} className="gym-logo__svg--ci" />
)

export const Gym10WhiteDELogo = (
    <Icon
        data={gym10YearsWhiteDE}
        className={cn('header-logo__svg', 'gym-logo__svg--white', style.logo)}
    />
)
export const Gym10YCoralDELogo = (
    <Icon
        data={gym10YearsCoralDE}
        className={cn('header-logo__svg', 'gym-logo__svg--ci', style.logo)}
    />
)

export const Gym10YWhiteENLogo = (
    <Icon
        data={gym10YearsWhiteEN}
        className={cn('header-logo__svg', 'gym-logo__svg--white', style.logo)}
    />
)
export const Gym10YCoralENLogo = (
    <Icon
        data={gym10YearsCoralEN}
        className={cn('header-logo__svg', 'gym-logo__svg--ci', style.logo)}
    />
)

export const LOGO_INITIAL_COMPONENT_MAP = {
    [LOGO_VARIANTS.CI]: () => ciLogo,
    [LOGO_VARIANTS.PRIDE]: () => prideLogo,
    [LOGO_VARIANTS.GYM_10_YEARS]: (isGermanLangauge) =>
        isGermanLangauge ? Gym10WhiteDELogo : Gym10YWhiteENLogo,
}

export const getInitialLogoComponent = (logoVariant, isGermanLangauge) => {
    return (
        LOGO_INITIAL_COMPONENT_MAP[logoVariant]?.(isGermanLangauge) || undefined
    )
}

export const getSecondaryLogoComponent = (logoVariant, isGermanLangauge) => {
    if (logoVariant === LOGO_VARIANTS.GYM_10_YEARS) {
        return isGermanLangauge ? Gym10YCoralDELogo : Gym10YCoralENLogo
    }

    return undefined
}
