import { isEU } from '@gymondo/frontend-core/utils/intl'
import {
    SET_INITIAL_STATE,
    SET_COOKIE_CONSENT_STATE,
    SET_CONSENTS,
    SET_SOCIAL_LOGIN_CONSENT,
    SET_CONSENT_TIER_CHOSEN,
} from './cookie-consent.constants'

const isEURegion = isEU()
const defaultState = {
    consentChosen: true,
    state: null,
    isEU: isEURegion,
    hasMarketingConsent: false,
    hasAnalyticsConsent: false,
    hasSocialLoginConsent: false,
    hasFirstPartyConsent: false,
}

const reducer = (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_INITIAL_STATE:
            return {
                ...state,
                ...payload,
            }
        case SET_COOKIE_CONSENT_STATE:
            return {
                ...state,
                state: payload,
            }
        case SET_CONSENT_TIER_CHOSEN:
            return {
                ...state,
                consentChosen: payload,
            }
        case SET_CONSENTS:
            return {
                ...state,
                ...payload,
                state: null,
            }
        case SET_SOCIAL_LOGIN_CONSENT:
            return {
                ...state,
                hasSocialLoginConsent: true,
            }
        default:
            return state
    }
}

export default reducer
