import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import LoginForm from './login-form'
import ForgotPassForm from './forgot-password-form'
import ChangePassForm from './change-password-form'
import ModalTitle from './modal-title'
import {
    setLoginModalLogin,
    setLoginModalReset,
} from '../../../service/page-header'

import style from './login-modal.module.scss'

const getFormComponent = (formType) => {
    switch (formType) {
        case 'login':
            return LoginForm
        case 'reset':
            return ForgotPassForm
        case 'change':
            return ChangePassForm
        default:
            return LoginForm
    }
}

export const LoginModal = ({
    forceOpen,
    onClose,
    formType,
    setLoginModalResetAction,
    setLoginModalLoginAction,
}) => {
    const containerRef = useRef(null)
    const [show, setShow] = useState(true)

    useEffect(() => {
        if (show) {
            disableBodyScroll(containerRef.current)
        } else {
            enableBodyScroll(containerRef.current)
        }

        function cleanup() {
            clearAllBodyScrollLocks()
            setLoginModalLoginAction()
        }

        return cleanup
    }, [show, setLoginModalLoginAction, containerRef])

    const handleClose = () => forceOpen === false && setShow(false)

    const modalTitleMessageId =
        formType === 'login' ? 'loginModal.login' : 'loginModal.forgotPassword'

    const Form = getFormComponent(formType)

    return (
        <div>
            <CSSTransition
                in={show}
                classNames="fade"
                appear
                exit
                timeout={250}
            >
                <div className={style.backdrop} />
            </CSSTransition>
            <div
                className={style.dialogWrapper}
                onClick={handleClose}
                ref={containerRef}
            >
                <CSSTransition
                    in={show}
                    classNames="slide"
                    appear
                    exit
                    timeout={250}
                    onExited={onClose}
                >
                    <div
                        className={style.dialog}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <div className={style.content}>
                            <ModalTitle
                                formattedMessageId={modalTitleMessageId}
                                onCloseClick={handleClose}
                                forceOpen={forceOpen}
                            />
                            <Form
                                onLinkClick={onClose}
                                onForgotClick={setLoginModalResetAction}
                            />
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

LoginModal.propTypes = {
    onClose: PropTypes.func,
    formType: PropTypes.string.isRequired,
    setLoginModalResetAction: PropTypes.func.isRequired,
    setLoginModalLoginAction: PropTypes.func.isRequired,
    forceOpen: PropTypes.bool,
}

LoginModal.defaultProps = {
    onClose: () => {},
    forceOpen: false,
}

export const mapStateToProps = (state) => ({
    formType: state.pageHeader.loginModalType,
})

export default connect(mapStateToProps, {
    setLoginModalLoginAction: setLoginModalLogin,
    setLoginModalResetAction: setLoginModalReset,
})(LoginModal)
