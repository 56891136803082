import React, { useContext } from 'react'
import cn from 'classnames'
import jsCookie from 'js-cookie'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'

import { navigateLocalized } from '../../..'
import PageContext from '../../../../config/intl/page-context.context'
import { getAllPageUrls, languages, ROUTES_ID } from '../../../../config/route'

import style from './language-switch.module.scss'

const LanguageSwitch = ({ isSignup }) => {
    const pageContext = useContext(PageContext)

    const { view, views, page, locale } = pageContext
    const preselectedOption = languages[locale.substring(0, 2)]
    const params = typeof window !== 'undefined' && window.location.search

    const navigateTo = event => {
        /**
         * Dynamic views are sent by the server, e.g. programs.
         * If there is dynamix views, but not for the defined language,
         * redirect to home (/)
         */
        jsCookie.set(
            COOKIE_NAMES.USER_LOCALE,
            languages[event.target.value].locale,
            { expires: 1000 }
        )

        const pageUrls = getAllPageUrls(page, view, views).filter(
            url => url.lang === event.target.value
        )
        const { url } = pageUrls[0] || {}

        if (url) {
            navigate(`${url}${params}`)
        } else {
            navigateLocalized({
                to: ROUTES_ID.HOME,
                lang: event.target.value,
            })
        }
    }

    return (
        <select
            className={cn(
                style.languageSwitch,
                'select',
                isSignup && style.languageSwitchSignup,
                isSignup && 'select--primary'
            )}
            value={preselectedOption.path}
            onChange={e => navigateTo(e)}
        >
            {Object.keys(languages).map(lang => (
                <option
                    key={languages[lang].locale}
                    value={languages[lang].path}
                >
                    {languages[lang].localeName}
                </option>
            ))}
        </select>
    )
}

LanguageSwitch.propTypes = {
    isSignup: PropTypes.bool,
}

LanguageSwitch.defaultProps = {
    isSignup: false,
}

export default LanguageSwitch
