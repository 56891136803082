import * as CONSTANTS from './layout.constants'

const initialState = {
    hasFloatingCta: false,
    footerType: 'STANDARD',
}
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CONSTANTS.SET_HAS_FLOATING_CTA:
            return {
                ...state,
                hasFloatingCta: payload,
            }
        case CONSTANTS.SET_CLASS_NAME:
            return {
                ...state,
                className: payload,
            }
        case CONSTANTS.SET_TEXT_COLOR_VARIANT:
            return {
                ...state,
                textColorVariant: payload,
            }
        case CONSTANTS.SET_LOGO_CLASSNAME:
            return {
                ...state,
                logoClassName: payload,
            }
        case CONSTANTS.SET_LOGO_VARIANT:
            return {
                ...state,
                logoVariant: payload,
            }
        case CONSTANTS.SET_SHOW_LOGOUT:
            return {
                ...state,
                showLogout: payload,
            }
        case CONSTANTS.SET_HIDE_ON_MOBILE:
            return {
                ...state,
                hideOnMobile: payload,
            }
        case CONSTANTS.SET_ALWAYS_TOP:
            return {
                ...state,
                alwaysTop: payload,
            }
        case CONSTANTS.SET_HIDE_NAVIGATION:
            return {
                ...state,
                hideNavigation: payload,
            }
        case CONSTANTS.SET_SHOW_USER_NAME:
            return {
                ...state,
                showUserName: payload,
            }
        case CONSTANTS.SET_FOOTER_TYPE:
            return {
                ...state,
                footerType: payload,
            }
        case CONSTANTS.SET_HIDE_BANNER:
            return {
                ...state,
                hideBanner: payload,
            }
        case CONSTANTS.SET_HIDE_LOGO:
            return {
                ...state,
                hideLogo: payload,
            }
        default:
            return state
    }
}

export default reducer
