import {
    createStore as createStoreRedux,
    applyMiddleware,
    compose,
} from 'redux'
import thunk from 'redux-thunk'

import appReducer from './reducer'

export const createStore = (initialState = {}) => {
    const devToolsExtension =
        typeof window !== 'undefined' &&
        window &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    const isDevEnv = process.env.NODE_ENV === 'development'

    const devtools =
        devToolsExtension && isDevEnv
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f

    // create redux store with middlewares
    const store = createStoreRedux(
        appReducer,
        initialState,
        compose(applyMiddleware(thunk), devtools)
    )

    return store
}

export default createStore({})
