import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { mediaXsOnly } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'
import { Tracker } from '@gymondo/frontend-core/utils'

import { LinkLocalized } from '..'
import { PATHS } from '../../config/route'
import { pageHeader as pageHeaderService } from '../../service'
import SimpleCta from '../simple-cta'
import { getTrialTitle } from '../../util/trial-interval.util'

export const Cta = ({
    isLoggedIn,
    isExistingUser,
    openLoginModal,
    displayXS,
    fixedXS,
    formattedMessageIdNew,
    className,
}) => {
    const formattedMessageIds = {
        loggedIn: 'cta.myTraining',
        existing: 'cta.login',
    }

    const matches = useMediaQuery(mediaXsOnly)

    let formattedMessageId
    let clickFunc = () => {}

    const intl = useIntl()
    if (isLoggedIn) {
        formattedMessageId = formattedMessageIds.loggedIn
    } else if (isExistingUser) {
        formattedMessageId = formattedMessageIds.existing
        clickFunc = openLoginModal
    } else {
        formattedMessageId = formattedMessageIdNew || 'cta.trialInterval.static'
    }

    const isFloating = matches && fixedXS

    const component = (
        <SimpleCta
            flex={false}
            size="big"
            onClick={clickFunc}
            fixed={isFloating}
            className={className}
        >
            <FormattedMessage id={formattedMessageId} />
        </SimpleCta>
    )

    if (!displayXS && matches) {
        return null
    }

    if (!isLoggedIn && !isExistingUser) {
        return (
            <LinkLocalized
                to="checkout"
                title={getTrialTitle(intl)}
                data-evt={Tracker.EVENT_TYPES.CLICK}
                data-category="global"
                data-action="click_image_cta"
                data-label="register_image"
            >
                {component}
            </LinkLocalized>
        )
    }

    if (!isLoggedIn && isExistingUser) {
        return component
    }

    return (
        <FormattedMessage id={formattedMessageId}>
            {(translatedMessage) => (
                <a href={PATHS.timeline} title={translatedMessage}>
                    {component}
                </a>
            )}
        </FormattedMessage>
    )
}

Cta.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isExistingUser: PropTypes.bool.isRequired,
    openLoginModal: PropTypes.func.isRequired,
    displayXS: PropTypes.bool,
    fixedXS: PropTypes.bool,
    formattedMessageIdNew: PropTypes.string,
    className: PropTypes.string,
}

Cta.defaultProps = {
    displayXS: false,
    fixedXS: true,
    formattedMessageIdNew: '',
    className: null,
}

const mapStateToProps = ({ user }) => ({
    ...user,
})

export default connect(mapStateToProps, {
    openLoginModal: pageHeaderService.openLoginModal,
})(Cta)
