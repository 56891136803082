import React from 'react'

import style from './build-info.module.scss'

const BuildInfo = () => {
    if (!process.env.GATSBY_BUILD_INFO) {
        return null
    }

    return <div className={style.wrapper}>{process.env.GATSBY_BUILD_INFO}</div>
}

export default BuildInfo
