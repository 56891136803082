export const NAME = 'cookieConsent'

export const SET_INITIAL_STATE = `${NAME}/SET_INITIAL_STATE`

export const SET_COOKIE_CONSENT_STATE = `${NAME}/ SET_COOKIE_CONSENT_STATE`

export const SET_CONSENTS = `${NAME}/SET_CONSENTS`

export const SET_SOCIAL_LOGIN_CONSENT = `${NAME}/SET_SOCIAL_LOGIN_CONSENT`

export const SET_CONSENT_TIER_CHOSEN = `${NAME}/SET_CONSENT_TIER_CHOSEN`
