import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withFormik, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'
import _ from 'lodash'
import {
    Button,
    InputNote,
    InputLocalized as Input,
    Alert,
} from '@gymondo/frontend-core/components'
import { Tracker, REGEX } from '@gymondo/frontend-core/utils'
import { LinkLocalized } from '../..'

import { PATHS, ROUTES_ID } from '../../../config/route'
import {
    redirectToForwardUrl,
    loginEmail,
} from '../../../api/service/login/login.service'

import { SOCIAL_APPS } from '../../../config/definitions'
import { getUserLocale } from '../../../api/generic/crud/crud'

import style from './login-form.module.scss'
import useSocialLogin from '../../../hook/use-social-login/use-social-login.hook'

const redirectToTimeline = () => {
    window.location.assign(PATHS.timeline)
}

const LoginForm = (props) => {
    const {
        values,
        handleSubmit,
        onForgotClick,
        onLinkClick,
        errors,
        isSubmitting,
        setErrors,
    } = props
    const isMounted = useRef(true)
    const locale = getUserLocale()
    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    const canSubmit =
        REGEX.email.test(values.mail) &&
        values.password.length > 7 &&
        !isSubmitting

    const handleSuccess = () => {
        // TODO: patch the user here
        console.log('should patch using ', locale)

        return redirectToForwardUrl() || redirectToTimeline()
    }

    const handleError = () => {
        if (isMounted.current) {
            const errorMessage = 'error.generic'
            setErrors({ social: errorMessage })
        }
    }

    const { startFlow } = useSocialLogin({
        onSuccess: handleSuccess,
        onError: handleError,
    })

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="mail"
                type="email"
                autoCorrect="off"
                autoCapitalize="none"
                variant="secondary"
                placeholder="loginForm.emailPlaceholder"
                component={Input}
                autoFocus
            />
            <Field
                name="password"
                type="password"
                variant="secondary"
                placeholder="loginForm.passwordPlaceholder"
                component={Input}
            />
            {errors._global && (
                <InputNote>
                    <FormattedMessage id={errors._global} />
                </InputNote>
            )}
            <div
                className={style.forgotPass}
                onClick={onForgotClick}
                data-evt={Tracker.EVENT_TYPES.CLICK}
                data-category="global"
                data-action="forgot_password_click"
            >
                <FormattedMessage id="loginForm.forgotPassword" />
            </div>

            <Button
                fluid
                htmlType="submit"
                disabled={!canSubmit}
                loading={isSubmitting}
            >
                <FormattedMessage id="loginForm.login" />
            </Button>
            <div className={style.separator}>
                <span className={style.separatorTitle}>
                    <FormattedMessage id="loginForm.or" />
                </span>
            </div>
            {errors.social && (
                <Alert className={cn(style.error)}>
                    <FormattedMessage id={errors.social} />
                </Alert>
            )}
            <div>
                <Button
                    type="facebook"
                    fluid
                    className={style.socialButton}
                    onClick={() => startFlow(SOCIAL_APPS.FACEBOOK)}
                >
                    <FormattedMessage id="loginForm.signIn.facebook" />
                </Button>
                <Button
                    type="google"
                    fluid
                    className={style.socialButton}
                    onClick={() => startFlow(SOCIAL_APPS.GOOGLE)}
                >
                    <FormattedMessage id="loginForm.signIn.google" />
                </Button>
                <Button
                    type="apple"
                    fluid
                    className={style.socialButton}
                    onClick={() => startFlow(SOCIAL_APPS.APPLE)}
                >
                    <FormattedMessage id="loginForm.signIn.apple" />
                </Button>
                <Button
                    type="amazon"
                    fluid
                    className={style.socialButton}
                    onClick={() => startFlow(SOCIAL_APPS.AMAZON)}
                >
                    <FormattedMessage id="loginForm.signIn.amazon" />
                </Button>
            </div>
            <div className={style.message}>
                <FormattedMessage id="loginForm.notRegistered" />{' '}
                <LinkLocalized
                    className="link"
                    onClick={onLinkClick}
                    to={ROUTES_ID.PUBLIC_PAGES_CHECKOUT_PACKAGE}
                    title="loginForm.registerNow"
                    data-evt={Tracker.EVENT_TYPES.CLICK}
                    data-category="global"
                    data-action="click_register_cta"
                    data-label="register_header"
                    isExternal
                >
                    <FormattedMessage id="loginForm.registerNow" />
                </LinkLocalized>
            </div>
        </form>
    )
}

LoginForm.propTypes = {
    values: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onForgotClick: PropTypes.func.isRequired,
    onLinkClick: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
}

const defaultValues = { mail: '', password: '' }

const EnhancedForm = withFormik({
    mapPropsToValues: () => defaultValues,
    validate: () => {},
    handleSubmit: async (values, { setSubmitting, setErrors }) => {
        const userPayload = {
            username: values.mail,
            password: values.password,
        }

        try {
            const redirected = await loginEmail(userPayload, true)
            setSubmitting(false)
            if (!redirected) {
                redirectToTimeline()
            }
        } catch (err) {
            setErrors({ _global: _.get(err, 'response.data.message') })
            setSubmitting(false)
        }
    },
    displayName: 'loginForm',
})(LoginForm)

export default EnhancedForm
