import React from 'react'
import { FormattedMessage } from 'react-intl'
import { LinkLocalized } from '../..'
import { ROUTES_ID } from '../../../config/route'

const spaceSeparator = ' '
const endOfLineDot = '. '

const CookieBannerMobileContent = () => (
    <>
        <FormattedMessage id="header.cookieMobileContent.part1" />
        {spaceSeparator}
        <LinkLocalized
            to={ROUTES_ID.PRIVACY}
            anchor="#5-cookies"
            title="header.cookieMobileLink"
        >
            <FormattedMessage id="header.cookieMobileLink" />
        </LinkLocalized>
        {endOfLineDot}
        <FormattedMessage id="header.cookieMobileContent.part2" />
    </>
)

export default CookieBannerMobileContent
