import { combineReducers } from 'redux'

import userReducer from '../../service/user/user.reducer'
import { NAME as userName } from '../../service/user/user.constants'
import pageHeaderReducer from '../../service/page-header/page-header.reducer'
import { NAME as pageHeaderName } from '../../service/page-header/page-header.constants'
import layoutReducer from '../../service/layout/layout.reducer'
import { NAME as layoutName } from '../../service/layout/layout.constants'
import programReduces from '../../page/generic/home/service/program.reducer'
import { NAME as programName } from '../../page/generic/home/service/program.constants'
import { NAME as cookieConsentName } from '../../service/cookie-consent/cookie-consent.constants'
import cookieConsentReducer from '../../service/cookie-consent/cookie-consent.reducer'
import { NAME as affiliateName } from '../../service/affiliate/affiliate.constants'
import affliateReducer from '../../service/affiliate/affiliate.reducer'
import { NAME as paymentSessionName } from '../../service/payment-session/session.constants'
import paymentSessionReducer from '../../service/payment-session/session.reducer'

// combine all the reducers
/**
 * @typedef {Object} ReduxState
 * @property {UserState} [userName]
 * @property {Object} [pageHeaderName]
 * @property {Object} [layoutName]
 * @property {Object} [programName]
 * @property {Object} [cookieConsentName]
 * @property {Object} [affiliateName]
 * @property {Object} [paymentSessionName]
 */
const reducer = combineReducers({
    [userName]: userReducer,
    [pageHeaderName]: pageHeaderReducer,
    [layoutName]: layoutReducer,
    [programName]: programReduces,
    [cookieConsentName]: cookieConsentReducer,
    [affiliateName]: affliateReducer,
    [paymentSessionName]: paymentSessionReducer,
})

export default reducer
