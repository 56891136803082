import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'

import style from './page-hero.module.scss'

const PageHero = ({
    title,
    subtitle,
    className,
    contentClassName,
    children,
}) => (
    <div className={cn(style.wrapper, className)}>
        <div className={cn('wrapper', style.content, contentClassName)}>
            <h1 className={cn('gym-h1', style.text)}>
                {title[0] && (
                    <div className={style.textLine1}>
                        <FormattedMessage id={title[0]} />
                    </div>
                )}
                {title[1] && (
                    <div>
                        <FormattedMessage id={title[1]} />
                    </div>
                )}
            </h1>
            {subtitle && (
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <h2
                            className={cn(
                                style.subText,
                                'gym-subtitle',
                                'hidden-xs'
                            )}
                        >
                            <FormattedMessage id={subtitle} />
                        </h2>
                    </div>
                </div>
            )}
        </div>
        {children}
    </div>
)

PageHero.defaultProps = {
    className: null,
    contentClassName: null,
    children: null,
}

PageHero.propTypes = {
    title: PropTypes.array.isRequired,
    subtitle: PropTypes.string.isRequired,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default PageHero
