/* eslint-disable react/prop-types */
import React from 'react'
import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { getPathUrl } from '../../config/route'

const LinkLocalized = ({
    innerRef,
    to,
    query,
    view,
    anchor,
    title,
    isExternal,
    ...props
}) => {
    const intl = useIntl()
    const { lang } = props

    /**
     * Language passed as a prop or get from page context
     */
    const lng = lang || intl.locale.split('-')[0]

    /**
     * Get the url to be accessed with: language, path, view, query and anchor
     */
    const pathWithQuery = getPathUrl({
        id: to,
        lang: lng,
        viewId: view,
        query,
        anchor,
    })

    if (isExternal) {
        return (
            <a
                href={pathWithQuery}
                {...(innerRef && { ref: innerRef })}
                {...props}
                title={title && intl.formatMessage({ id: title })}
            />
        )
    }

    return (
        <Link
            {...(innerRef && { innerRef })}
            {...props}
            to={pathWithQuery}
            title={title && intl.formatMessage({ id: title })}
        />
    )
}

LinkLocalized.defaultProps = {
    lang: null,
    query: null,
    isExternal: false,
}

LinkLocalized.propTypes = {
    to: PropTypes.string.isRequired,
    query: PropTypes.object,
    lang: PropTypes.string,
    isExternal: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export const navigateLocalized = ({ to, lang, query, view, anchor }) =>
    navigate(getPathUrl({ id: to, lang, query, viewId: view, anchor }))

export default LinkLocalized
